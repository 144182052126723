import instance from "../config/axios"

export function calidadGetAll() {
    let token = localStorage.getItem("token")
    return instance.get(`calidad/`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function calidadCreate(calidad) {
    let token = localStorage.getItem("token")
    return instance.post(`calidad/`, calidad, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function calidadUpdate(id, calidad) {
    let token = localStorage.getItem("token")
    return instance.put(`calidad/${id}`, calidad, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function calidadDelete(id) {
    let token = localStorage.getItem("token")
    return instance.delete(`calidad/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

