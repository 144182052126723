import { Toolbar } from "@mui/material";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";

//Icons
import CalidadAdd from "../calidad-add";

export default function EnhancedTableToolbar({ handleReload}) {

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        pt: { xs: 0, sm: 0 },
        pb: { xs: 0, sm: 0 }

      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
        style={{ fontSize: "13pt" }}
      >
        Registros de Calidad
      </Typography>
      <CalidadAdd handleReload={handleReload}/>
    </Toolbar >
  );
};
