import {
  Box,
  Card,
  Divider,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Avatar,
  TableBody,
  Badge
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { dashboardPerformanceOperadores } from '../../services/dashboard';
import { getInitials } from '../../utils/get-initials';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { visuallyHidden } from '@mui/utils';
import TableSortLabel from '@mui/material/TableSortLabel';
import { StyledCardHeader } from '../../styled-components/styled-cardheader';
import { cardData } from '../../utils/data/dashboard-texts';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
  fontSize: "0.7rem"
}));

export const OperadoresTable = ({ filters, ...props }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  //Sort states and functions
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('informes_pendientes_historico');
  const handleRequestSort = (event, property) => {
    setLoading(true);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  useEffect(() => {
    setLoading(true)
    async function getList() {
      try {
        const res = await dashboardPerformanceOperadores({ filters: filters, order: order, orderBy: orderBy, })
        setData(res.data)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    getList()
  }, [filters, order, orderBy])

  return (
    < Card  {...props}>
      <StyledCardHeader
        title={cardData?.PerformanceDeInspectoresTabla?.title}
        helpText={cardData?.PerformanceDeInspectoresTabla?.helpText}
        data={data}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              Inspector
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "informes_pendientes_historico"}
                direction={orderBy === "informes_pendientes_historico" ? order : 'asc'}
                onClick={createSortHandler("informes_pendientes_historico")}
              >
                {"Informes pendientes"}
                {orderBy === "informes_pendientes_historico" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "inspecciones_realizadas_mes_actual"}
                direction={orderBy === "inspecciones_realizadas_mes_actual" ? order : 'asc'}
                onClick={createSortHandler("inspecciones_realizadas_mes_actual")}
              >
                {"Inspecciones realizadas este mes"}
                {orderBy === "inspecciones_realizadas_historico" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "gap_inspeccion_informe_mes_actual"}
                direction={orderBy === "gap_inspeccion_informe_mes_actual" ? order : 'asc'}
                onClick={createSortHandler("gap_inspeccion_informe_mes_actual")}
              >
                {"Días promedio de retraso este mes"}
                {orderBy === "gap_inspeccion_informe_mes_actual" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>

            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((user, index) => {

            return (
              <TableRow key={index} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <SmallAvatar>{index + 1}</SmallAvatar>
                      }>
                      <Avatar
                        src={user.image ? `${process.env.REACT_APP_BACKEND_URL}${user.image}` : ""}
                      >
                        {getInitials(user?.nombre)}
                      </Avatar>
                    </Badge>
                    <Box style={{ paddingLeft: "1em" }}>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                        component="div"
                        style={{ fontSize: "0.85em" }}
                      >
                        {user.apellido.toUpperCase()}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="bpdy2"
                        component="div"
                        style={{ fontSize: "0.85em" }}
                      >
                        {user.nombre}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <Box style={{ width: "180px" }}>
                    <Box style={{
                      display: 'flex',
                      justifyContent: 'left',
                    }}>
                      <Box
                        style={{
                          width: "55px",
                          display: 'flex',
                          justifyContent: 'right'
                        }}>
                        <Typography
                          color="textPrimary"
                          variant="h3"
                          component="div"
                        >
                          {user.informes_pendientes_historico}
                        </Typography>
                      </Box>
                      <Box style={{ padding: "0.5em 0.5em 0em 0.5em" }}>
                        <Box style={{
                          display: 'flex',
                        }}>
                          <Box >
                            <Typography
                              color="textSecondary"
                              variant="bpdy2"
                              component="div"
                              style={{ fontSize: "0.8em" }}
                            >
                              {`${user.informes_pendientes_mes_actual} este mes`}
                            </Typography>
                            
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <Box style={{ width: "230px" }}>
                    <Box style={{
                      display: 'flex',
                      justifyContent: 'left',
                    }}>
                      <Box
                        style={{
                          width: "55px",
                          display: 'flex',
                          justifyContent: 'right'
                        }}>
                        <Typography
                          color="textPrimary"
                          variant="h3"
                          component="div"
                        >
                          {user.inspecciones_realizadas_mes_actual}
                           
                        </Typography>
                        
                      </Box>
                      
                      <Box style={{ padding: "0.5em 0.5em 0em 0.5em" }}>
                        <Box style={{
                          display: 'flex',
                        }}>
                          <Box >
                            <Typography
                              color="textSecondary"
                              variant="bpdy2"
                              component="div"
                              style={{ fontSize: "0.8em" }}
                            >
                              {`${user.inspecciones_realizadas_historico} en total`}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              variant="bpdy2"
                              component="div"
                              style={{ fontSize: "0.8em" }}
                            >
                              {user.inspecciones_realizadas_comparacion_mensual < 0 &&
                                <ArrowDownwardIcon color='error' sx={{ fontSize: 'inherit' }} />
                              }
                              {user.inspecciones_realizadas_comparacion_mensual > 0 &&
                                <ArrowUpwardIcon color='success' sx={{ fontSize: 'inherit' }} />
                              }
                              {`${user.inspecciones_realizadas_comparacion_mensual === null ? "-" : user.inspecciones_realizadas_comparacion_mensual}% que el mes pasado`}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box style={{ width: "230px" }}>
                    <Box style={{
                      display: 'flex',
                      justifyContent: 'left',
                    }}>
                      <Box
                        style={{
                          width: "70px",
                          display: 'flex',
                          justifyContent: 'right'
                        }}>
                        <Typography
                          color="textPrimary"
                          variant="h3"
                          component="div"

                        >
                          {user.gap_inspeccion_informe_mes_actual}
                        </Typography>
                      </Box>
                      <Box style={{ padding: "0.5em 0.5em 0em 0.5em" }}>
                        <Box style={{
                          display: 'flex',
                        }}>
                          <Box >
                            <Typography
                              color="textSecondary"
                              variant="bpdy2"
                              component="div"
                              style={{ fontSize: "0.8em" }}
                            >
                              {`${user.gap_inspeccion_informe_historico} histórico`}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              variant="bpdy2"
                              component="div"
                              style={{ fontSize: "0.8em" }}
                            >
                              {user.gap_comparacion_mensual > 0 &&
                                <ArrowUpwardIcon color='error' sx={{ fontSize: 'inherit' }} />
                              }
                              {user.gap_comparacion_mensual < 0 &&
                                <ArrowDownwardIcon color='success' sx={{ fontSize: 'inherit' }} />
                              }
                              {`${user.gap_comparacion_mensual === null ? "-" : user.gap_comparacion_mensual}% que el mes pasado`}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>

            )

          })


          }
        </TableBody>
      </Table>
    </Card >
  )
}