import instance from "../config/axios"

export function reportesGetAll() {
    let token = localStorage.getItem("token")
    return instance.get(`reportes/`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function reporteGenerate({ filters, data }) {
    let token = localStorage.getItem("token")
    return instance.post(`reportes?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${"mensual"}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {data}, {

        headers: { Authorization: `Bearer ${token}` }
    })
}

export const reporteGetPDF = async (url) => {

    try {
        let token = localStorage.getItem("token")
        const response = await instance.get(url,
            {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'arraybuffer'
            });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        return blob;
    } catch (error) {
        console.error('Error fetching PDF:', error);
        return null;
    }
};

export const reporteDelete = (url) => {
    let token = localStorage.getItem("token")
    console.log(url)
    return instance.delete(`reportes/${url}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
};


