import instance from "../config/axios"
import * as qs from 'qs'

//rutas Axios

export function parteGetRestricted(page, rowsPerPage, order, orderBy, search, requestId) {
    let token = localStorage.getItem("token")
    return instance.get(`partes/restricted?page=${page + 1}&rowsPerPage=${rowsPerPage}&order=${order}&orderBy=${orderBy}&requestId=${requestId}&${qs.stringify(search)}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  }

  export function parteGetRestrictedClient(page, rowsPerPage, order, orderBy, search, requestId) {
    let token = localStorage.getItem("token")
    return instance.get(`partes/restricted-client?page=${page + 1}&rowsPerPage=${rowsPerPage}&order=${order}&orderBy=${orderBy}&requestId=${requestId}&${qs.stringify(search)}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  }

export function parteGetTable(order, orderBy, search) {
    let token = localStorage.getItem("token")
    return instance.get(`partes/gettable?&order=${order}&orderBy=${orderBy}&${qs.stringify(search)}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function parteGetTableRestricted(order, orderBy, search) {
    let token = localStorage.getItem("token")
    return instance.get(`partes/gettablerestricted?&order=${order}&orderBy=${orderBy}&${qs.stringify(search)}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function parteGetUnrestricted() {
    let token = localStorage.getItem("token")
    return instance.get(`partes/unrestricted`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function parteOne(id) {
    let token = localStorage.getItem("token")
    return instance.get(`partes/one/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function parteCreate(parte) {
    let token = localStorage.getItem("token")
    return instance.post(`partes/create`, parte, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function parteMasiva(parte) {
    let token = localStorage.getItem("token")
    return instance.post(`partes/masiva`, parte, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function parteEdit({ data, id }) {
    let token = localStorage.getItem("token")
    return instance.put(`partes/edit/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function parteEstado({ data, id }) {
    let token = localStorage.getItem("token")
    return instance.put(`partes/estado/?selected=${id}`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function parteChangePrices({ valor_total, valor_unitario, cantidad, id, subid }) {
    let token = localStorage.getItem("token")
    return instance.put(`partes/prices/${id}-${subid}`, { valor_total: valor_total, valor_unitario: valor_unitario, cantidad: cantidad }, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function parteDeleteRemito({ data, id }) {
    let token = localStorage.getItem("token")
    return instance.put(`partes/remitoDelete/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
export function parteDeleteCertificado({ data, id }) {
    let token = localStorage.getItem("token")
    return instance.put(`partes/certificadoDelete/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function parteDelete(id) {
    let token = localStorage.getItem("token")
    return instance.delete(`partes/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function parteDeleteMany(selected) {
    let token = localStorage.getItem("token")
    return instance.delete(`partes/many/${selected}`,
        { headers: { Authorization: `Bearer ${token}` } })
}