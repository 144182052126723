import { Box, Card, IconButton, ListItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { calidadDelete, calidadGetAll } from "../../services/calidad"

import EnhancedTableToolbar from "./components/table/enhanced-table-toolbar";
import { format } from "date-fns";

//Icons
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOff from "@mui/icons-material/HighlightOff";

export const CalidadListResults = ({handleConfirmDialogChange, handleNotifyChange, ...props}) => {
    const [data, setData] = useState([])
    const [reload, setReload] = useState(false)

    const handleReload = () => {
        setReload(!reload)
    }

    const handleDelete = async (id) => {
        try {
            await calidadDelete(id)
            handleConfirmDialogChange({
                isOpen: false,
                title: "",
                subTitle: ""
            })
            handleNotifyChange({
                isOpen: true,
                message: 'El registro se eliminó correctamente',
                type: 'error'
            })
            handleReload()
        } catch (e) {
            handleNotifyChange({
                isOpen: true,
                message: 'Hubo un error al intentar eliminar el registro',
                type: 'error'
            })
        }
    }

    useEffect(() => {
        const getData = async () => {
            const res = await calidadGetAll()
            setData(res.data)
        }
        getData()
    }, [reload])

    return (
        <Card>
            <EnhancedTableToolbar handleReload={handleReload} />
            <Paper sx={{ overflowX: "auto", width: "100%", height: `calc(100vh - 150px)` }}>
                <Table size="small" stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                ID
                            </TableCell>
                            <TableCell>
                                Fecha
                            </TableCell>
                            <TableCell>
                                Referencia Cliente
                            </TableCell>
                            <TableCell>
                                Referencia GIE
                            </TableCell>
                            <TableCell>
                                Descripción
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((line, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        {line.Id}
                                    </TableCell>
                                    <TableCell>
                                        {line.fecha ? format(new Date(line.fecha), 'dd/MM/yyyy') : "-"}
                                    </TableCell>
                                    <TableCell>
                                        {line.ref_cliente}
                                    </TableCell>
                                    <TableCell>
                                        {line.ref_gie}
                                    </TableCell>
                                    <TableCell>
                                        {line.descripcion}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Eliminar registro">
                                            <IconButton sx={{ width: "25px", height: "25px", fontSize: '12pt' }} onClick={() => {
                                                handleConfirmDialogChange({
                                                    isOpen: true,
                                                    title: "¿Desea eliminar este registro?",
                                                    subTitle: "Luego de eliminarlo, no podrás recuperar la información.",
                                                    onConfirm: () => { handleDelete(line._id) },
                                                    icon: <HighlightOff fontSize='inherit' color="error" />
                                                })
                                            }}>
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>

                </Table>
            </Paper>
        </Card >
    )


}