import { Card, Typography, Grid, Box, Chip, Divider, Table, TableHead, TableRow, TableCell, Paper, TableBody } from '@mui/material';
import { useEffect, useState } from 'react';
import { dashboardKPIGrupal } from '../../services/dashboard';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const StatusIcon = ({ status, ...props }) => {
  const iconSize = 'small'; 

  return (
    <>
      {status === 'Insuficiente' && (
        <CloseIcon color="insuficiente" fontSize={iconSize} />
      )}
      {status === 'Bueno' && (
        <DoneIcon color="bueno" fontSize={iconSize} />
      )}
    </>
  );
};

function KpiGrupalesTabla({ filters, handleReload, reload, ...props }) {
  const [data, setData] = useState({})
  useEffect(() => {
    async function getList() {
      try {
        const res = await dashboardKPIGrupal({ filters: filters })
        setData(res.data.at(0))

      } catch (error) {
        console.log(error)
      }
    }
    getList()
  }, [filters, reload])

  const selectColor = (productividad_labels) => {
    let color = '';
    switch (productividad_labels) {
      case 'Insuficiente':
        color = 'insuficiente'; // red
        break;
      case 'Justo':
        color = 'justo'; // orange
        break;
      case 'Bueno':
        color = 'bueno'; // teal
        break;
      case 'Muy bueno':
        color = 'muybueno'; // darker teal
        break;
      case 'Excelente':
        color = 'excelente'; // purple
        break;
      case 'Excepcional':
        color = 'excepcional'; // pink
        break;
      default:
        color = undefined; // black (default color)
        break;
    }
    return color;
  }

  return (
    <Card style={{ height: '100%' }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1
        }}
        style={{ padding: "1em 1em 0.7em 1.4em" }}
      >
        <Typography
          sx={{ m: 1 }}
          variant="h6"
          style={{ fontSize: "1em" }}
        >
          Último mes
        </Typography>
      </Box>
      <Divider />
      <Paper sx={{ overflowX: "auto", width: "100%" }}>
        <Table size='small'>
          <TableHead style={{ height: "45px" }}>
            <TableRow>
              <TableCell>KPI</TableCell>
              <TableCell align='right'>Valor</TableCell>
              <TableCell></TableCell>
              <TableCell align='right'>Adicionales</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Productividad</TableCell>
              <TableCell align='right'>{typeof (data?.productividad) === 'number' ? data?.productividad?.toFixed(2) : "S/D"}</TableCell>
              <TableCell align='center' padding='none'> <Chip label={data?.productividad_labels} size='small' color={selectColor(data?.productividad_labels)} /></TableCell>
              <TableCell align='right'>{data?.PROD >= 0 ? data?.PROD?.toFixed(2) : "S/D"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Eficiencia</TableCell>
              <TableCell align='right'>{typeof (data?.eficiencia) === 'number' ? data?.eficiencia?.toFixed(2) : "S/D"}</TableCell>
              <TableCell align='center' padding='none'> <Chip label={data?.eficiencia_labels} size='small' color={selectColor(data?.eficiencia_labels)} /></TableCell>
              <TableCell align='right'>{typeof (data?.EF) === 'number' ? data?.EF?.toFixed(2) : "S/D"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Calidad</TableCell>
              <TableCell align='right'>{typeof (data?.calidad) === 'number' ? data?.calidad?.toFixed(2) : "S/D"}</TableCell>
              <TableCell align='center' padding='none'><Chip label={data?.calidad_labels} size='small' color={selectColor(data?.calidad_labels)} /></TableCell>
              {/* <TableCell align='right'>{typeof(data?.CAL)==='number' ? data?.CAL?.toFixed(2) : "S/D"}</TableCell> */}
              <TableCell align='right'><StatusIcon status={data?.calidad_labels} /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Seguridad</TableCell>
              <TableCell align='right'>{typeof (data?.seguridad) === 'number' ? data?.seguridad?.toFixed(2) : "S/D"}</TableCell>
              <TableCell align='center' padding='none'><Chip label={data?.seguridad_labels} size='small' color={selectColor(data?.seguridad_labels)} /></TableCell>
              {/* <TableCell align='right'>{typeof(data?.SEG)==='number' ? data?.SEG?.toFixed(2) : "S/D"}</TableCell> */}
              <TableCell align='right'><StatusIcon status={data?.seguridad_labels} /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Mes</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align='right' >{typeof (data?.adicionales) === 'number' ? data?.adicionales?.toFixed(2) : "S/D"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

export default KpiGrupalesTabla