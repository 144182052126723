import { Box, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HelpIcon from '@mui/icons-material/Help';

export const StyledCardHeader = ({ title, data, helpText, genericoXLS, children, ...props }) => {
    return (
        <>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    m: -1
                }}
                style={{ padding: "1em 1em 0.3em 1.4em" }}
            >
                <Grid
                    container
                    spacing={1}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item style={{ width: '85%' }}>
                        <Typography
                            sx={{ m: 1 }}
                            variant="h6"
                            style={{ fontSize: "1em" }}
                        >
                            {title}
                            {helpText &&
                                <Tooltip title={helpText}>
                                    <IconButton size='small' >
                                        <HelpIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>}
                            {children}
                        </Typography>

                    </Grid>
                    {genericoXLS && (
                        <Grid item>
                            <Box sx={{ m: 1 }}>
                                <IconButton
                                    variant="contained"
                                    size='small'
                                    onClick={() => { genericoXLS({ data: data.datasets }) }}
                                >
                                    <FileDownloadIcon fontSize='inerhit' />
                                </IconButton>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Divider />
        </>
    );
};