import instance from "../config/axios"

export function seguridadGetAll() {
    let token = localStorage.getItem("token")
    return instance.get(`seguridad/`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function seguridadCreate(seguridad) {
    let token = localStorage.getItem("token")
    return instance.post(`seguridad/`, seguridad, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function seguridadUpdate(id, seguridad) {
    let token = localStorage.getItem("token")
    return instance.put(`seguridad/${id}`, seguridad, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function seguridadDelete(id) {
    let token = localStorage.getItem("token")
    return instance.delete(`seguridad/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

