import { Line } from 'react-chartjs-2';
import { Box, Grid, Card, Divider, Typography } from '@mui/material';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardKPIGrupal } from '../../services/dashboard';
import { segmentacionSettings } from "../../utils/segmentacion-settings";
import { useTheme } from '@mui/material/styles';
Chart.register(...registerables);

export const KPICalidad = ({ filters, handleReload, ...props }) => {
  const settings = segmentacionSettings('mensual')
  const [results, setResults] = useState([])
  const theme = useTheme();
  
  useEffect(() => {
    const getData = async () => {
      const res = await dashboardKPIGrupal({ filters: filters })
      setResults(res.data)
    }
    getData()
  }, [filters])

  //Obtiene las fechas de inicio y fin redondeadas
  const data = {
    datasets: [
      {
        label: "Calidad",
        data: results.map((d) => {
          return ({ date: d.fecha, value: d.calidad })
        }),
        borderColor: '#534c47',
        yAxisID: 'y1',
      },
      {
        label: "Calidad Histórico",
        data: results.map((d) => {
          return ({ date: d.fecha, value: d.calidad_historico })
        }),
        borderColor: '#534c47',
        borderWidth: 0.7,
        yAxisID: 'y1',
      },
      {
        label: "Calidad Buena",
        data: results.map((d) => {
          return ({ date: d.fecha, value: 4 })
        }),
        backgroundColor: `${theme.palette.insuficiente.light}`,
        borderWidth: 0,
        yAxisID: 'y1',
        fill: '+1',
        pointRadius: 0,
      },
      {
        label: "Calidad Buena",
        data: results.map((d) => {
          return ({ date: d.fecha, value: d.calidad_bueno })
        }),
        backgroundColor: `${theme.palette.bueno.light}`,
        borderWidth: 0,
        yAxisID: 'y1',
        fill: 'origin',
        pointRadius: 0,
      },
    ]
  };
  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index'
      }
    },
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'value',
    },
    scales: {
      x: {
        type: 'time',
        time: {
          parser: settings.parser,
          unit: settings.unit,
          format: settings.timeFormat,
          tooltipFormat: settings.tooltipFormat,
          displayFormats: settings.displayFormats
        },
      },
      y1: {
        //min:4,
        max:4,
        type: 'linear',
        display: true,
        grid: {
          drawOnChartArea: false,
        }
      },
    },
  };

  return (
    <Card {...props}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1
        }}
        style={{ padding: "1em 1em 0.3em 1.4em" }}
      >
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item style={{ width: '85%' }}>
            <Typography
              sx={{ m: 1 }}
              variant="h6"
              style={{ fontSize: "1em" }}
            >
              Calidad
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />

      <Box
        sx={{
          height: 200,
          position: 'relative',
          padding: '0.5em 1em 0.5em 1em'
        }}
      >

        <Line
          data={data}
          options={options}
        />
      </Box>
    </Card>
  );
};
