import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Tooltip } from "@mui/material";
import { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { seguridadSchema } from '../../../utils/yup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DialogActions from '@mui/material/DialogActions';
import StyledTextfield from '../../../styled-components/styled-textfield';
import StyledDatepickerDesktop from '../../../styled-components/styled-datepicker-desktop';

//Alerts y Notifications
import Notification from '../../../styled-components/alerts/notification';
import ConfirmDialog from '../../../styled-components/alerts/confirm-dialog';

//Icons
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { seguridadCreate } from '../../../services/seguridad';

export default function SeguridadAdd({ handleReload}) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "success" })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" })
    const { control, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm({
        resolver: yupResolver(seguridadSchema),
    });

    useEffect(() => {
        reset({fecha:new Date()})
    }, [open])

    async function addRegistro(seguridad) {
        try {
            await seguridadCreate(seguridad)
            setConfirmDialog({
                isOpen: false,
                title: "",
                subTitle: "",
            })
            setNotify({
                isOpen: true,
                message: 'El registro se ha cargado correctamente',
                type: 'success'
            })
            handleReload()
            handleClose()
            handleReload()
        } catch (e) {
            setNotify({
                isOpen: true,
                message: 'Ha habido un error, intente nuevamente',
                type: 'error'
            })
            handleClose()
        }
    }

    async function onSubmit(registro) {
        setConfirmDialog({
            isOpen: true,
            title: "¿Desea agregar el registro?",
            subTitle: "",
            onConfirm: () => { addRegistro(registro) },
            icon: <AddCircleOutlineIcon fontSize='inherit' color="success" />
        })
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Tooltip title="Nuevo Registro">
                    <IconButton size="small" color="primary" aria-label="add" onClick={handleClickOpen}>
                        <AddIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
            </Box>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    style: {
                        backgroundColor: 'white',
                    },
                }}
                keepMounted={false}
            >
                <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "#F3F4F6" }}>
                    <IconButton disabled style={{ padding: "0em 0.2em 0.2em 0em" }}>
                        <NoteAddIcon />
                    </IconButton>
                    {"Nuevo Registro de Seguridad"}

                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ padding: "1.5em 3em 1.5em 3em" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <form id="myform" onSubmit={handleSubmit(data => onSubmit(data))}>

                            <Grid container spacing={2}>
                                <StyledDatepickerDesktop control={control} name="fecha" description="Fecha*" errors={errors} md={12} xs={12} size="small" />
                                <StyledTextfield show={true} control={control} name={`ref_cliente`} type="text" description="Referencia GIE" errors={errors} md={12} xs={12} size="small" />
                                <StyledTextfield show={true} control={control} name={`ref_gie`} type="text" description="Referencia Cliente" errors={errors} md={12} xs={12} size="small" />
                                <StyledTextfield show={true} control={control} name={`descripcion`} type="text" description="Descripción" errors={errors} md={12} xs={12} size="small" />
                            </Grid>

                        </form>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions >
                    <Button type='submit' form="myform" color="primary" variant="contained" disabled={isSubmitting} fullWidth onClick={() => { }
                    }>
                        Añadir Registro
                    </Button>
                </DialogActions>
            </Dialog>
            <Notification
                notify={notify}
                setNotify={setNotify} />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog} 
            />
        </div>
    );
}