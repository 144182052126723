import { Bar } from 'react-chartjs-2';
import { Box, Card, FormControlLabel, Switch } from '@mui/material';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardEnsayoTipoActividadBarrasCantidades } from '../../services/dashboard';
import { colors_palette } from "../../utils/colors-palette";
import { segmentacionSettings } from "../../utils/segmentacion-settings";
import { dateFill } from '../../utils/date-fill';
import { genericoXLS } from '../../utils/exports/generico-xls'
import { StyledCardHeader } from '../../styled-components/styled-cardheader';
import { cardData } from '../../utils/data/dashboard-texts';
Chart.register(...registerables);

export const EnsayosTipoActividadBarrasCantidad = ({ filters, ...props }) => {
  const settings = segmentacionSettings(filters.segmentacion)
  const [results, setResults] = useState([])
  const [agrupado, setAgrupado] = useState(true)

  useEffect(() => {
    const getData = async () => {
      const res = await dashboardEnsayoTipoActividadBarrasCantidades({
        filters: {
          ...filters,
          //segmentacion: 'mensual',
          agrupado: agrupado ? ['RD', 'RC', 'IV', 'PT', 'MT', 'EXT', 'CAL', 'UT', 'INT'] : []
        }
      })
      setResults(res.data)
    }
    getData()
  }, [filters, agrupado])
  const data = {
    datasets:
      results.map((dato, index) => {
        return (
          {
            label: dato._id,
            data: dateFill(dato.cantidades, filters.segmentacion, filters.fecha_inicio, filters.fecha_fin),
            backgroundColor: colors_palette[index]
          })
      })
    ,
  };

  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 5,
          boxWidth: 12,
          boxHeight: 6,
        }
      },
    },
    responsive: true,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'value'
    },
    scales: {
      x: {
        stacked: true,
        type: 'time',
        time: {
          //isoWeekday:true,
          parser: settings.parser,
          unit: settings.unit,
          format: settings.timeFormat,
          tooltipFormat: settings.tooltipFormat,
          displayFormats: settings.displayFormats
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <Card {...props}>
      <StyledCardHeader
        title={cardData?.cantidadDeEnsayosPorTipoBarras?.title}
        helpText={cardData?.cantidadDeEnsayosPorTipoBarras?.helpText}
        data={data}
        genericoXLS={genericoXLS}>
        <FormControlLabel control={<Switch defaultChecked onChange={() => setAgrupado(!agrupado)} />} label={'Agrupar'} />
      </StyledCardHeader>
      <Box
        sx={{
          height: 350,
          position: 'relative',
          padding: '0.5em 1em 1em 1em'
        }}
      >
        <Bar
          data={data}
          options={options}
        />
      </Box>
    </Card>
  );
};
