import { useEffect, useState, useContext } from 'react';
import './App.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserContext from './context/userContext';

//Private Routes
import Private from './components/Private';

//Autenticación inicial
import { userWhoami } from './services/users';

//Páginas
import Inicio from './pages/inicio';
import Dashboard from "./pages/dashboard";
import UsersLogin from './pages/users-login';
import PartesList from './pages/partes-list';
import PartesFull from './pages/partes-full';
import ClientsList from './pages/clients-list';
import UsersList from './pages/users-list';
import ContractsList from './pages/contracts-list';
import ContractsEdit from './pages/contracts-edit';
import RemitosList from './pages/remitos-list';
import ClientsEdit from './pages/clients-edit';
import CertificadosList from './pages/certif-list';
import UsersCreate from './pages/users-create';
import ClientsCreate from './pages/clients-create';
import UsersEdit from './pages/users-edit';
import Loading from './pages/loading';
import SessionTimeout from './styled-components/alerts/session-timeout';
import Settings from './pages/settings';
import DashboardClients from './pages/dashboard-clients';
import KPI from './pages/kpi';
import SeguridadList from './pages/seguridad-list';
import CalidadList from './pages/calidad-list';
import FactoresList from './pages/factores-list';
import KPIInspectores from './pages/kpi-inspectores';
import KPIHistorial from './pages/kip-historial';
import Reportes from './pages/reportes';
import PartesListClient from './pages/partes-list-client';

function App() {
  console.log(process.env.REACT_APP_BACKEND_URL)
  const [user, setUser] = useContext(UserContext);
  const [loadingUser, setLoadingUser] = useState(true)
  const [expirationTime, setExpirationTime] = useState(1000000000000)

  useEffect(() => {
    const Login = async () => {
      try {
        const { data: usuario } = await userWhoami()
        setExpirationTime(usuario.exp)
        setUser(usuario[0])
        setLoadingUser(false)
      } catch (e) {
        console.log(e)
        setLoadingUser(false)
      }
    }
    Login()
  }, []
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {
        loadingUser === true ?
          <Loading /> :
          <BrowserRouter>
            <Routes>

              {/* Inicio */}
              <Route path="/" element={<Private Component={Inicio} user={user} roles={["Administrador", "Supervisor", "Inspector", "Asistente", "Cliente"]} />} />
              <Route path="/inicio" element={<Private Component={Inicio} user={user} roles={["Administrador", "Supervisor", "Inspector", "Asistente"]} />} />

              {/* Dashboard */}
              <Route path="/dashboard" element={<Private Component={Dashboard} user={user} roles={["Administrador", "Supervisor"]} />} />

              {/* KPI */}
              <Route path="/kpi" element={<Private Component={KPI} user={user} roles={["Administrador"]} />} />
              <Route path="/kpi-inspectores" element={<Private Component={KPIInspectores} user={user} roles={["Administrador", "Inspector", "Supervisor", "Asistente"]} />} />

              {/* Dashboard Clients*/}
              <Route path="/dashboard-cliente" element={<Private Component={DashboardClients} user={user} roles={["Administrador", "Cliente"]} />} />

              {/* Reportes*/}
              <Route path="/reportes" element={<Private Component={Reportes} user={user} roles={["Administrador"]} />} />

              {/* Users */}
              {/* <Route path="/users-account" element={<Private Component={UsersAccount} user={user} roles={["Administrador", "Supervisor", "Inspector", "Asistente"]} />} /> */}
              <Route path="/users-create" element={<Private Component={UsersCreate} user={user} roles={["Administrador"]} />} />
              <Route path="/users-edit/:id" element={<Private Component={UsersEdit} user={user} roles={["Administrador", "Supervisor", "Inspector", "Asistente", "Cliente"]} />} />
              <Route path="/users-list" element={<Private Component={UsersList} user={user} roles={["Administrador"]} />} />
              <Route path="/users-login" element={<UsersLogin />} />

              {/* Partes */}
              <Route path="/partes-list" element={<Private Component={PartesList} user={user} roles={["Administrador", "Supervisor", "Inspector", "Asistente"]} />} />
              <Route path="/partes-full" element={<Private Component={PartesFull} user={user} roles={["Administrador", "Supervisor", "Inspector", "Asistente"]} />} />
              <Route path="/partes-list-client" element={<Private Component={PartesListClient} user={user} roles={["Administrador", "Cliente"]} />} />

              {/* Contracts */}
              <Route path="/contracts-edit/:id" element={<Private Component={ContractsEdit} user={user} roles={["Administrador"]} />} />
              <Route path="/contracts-list" element={<Private Component={ContractsList} user={user} roles={["Administrador"]} />} />

              {/* Clients */}
              <Route path="/clients-edit/:id" element={<Private Component={ClientsEdit} user={user} roles={["Administrador"]} />} />
              <Route path="/clients-list" element={<Private Component={ClientsList} user={user} roles={["Administrador"]} />} />
              <Route path="/clients-create" element={<Private Component={ClientsCreate} user={user} roles={["Administrador"]} />} />

              {/* Remitos */}
              <Route path="/remitos-list" element={<Private Component={RemitosList} user={user} roles={["Administrador", "Supervisor", "Asistente"]} />} />

              {/* Certificados */}
              <Route path="/certif-list" element={<Private Component={CertificadosList} user={user} roles={["Administrador", "Asistente"]} />} />

              {/* Seguridad*/}
              <Route path="/seguridad-list" element={<Private Component={SeguridadList} user={user} roles={["Administrador"]} />} />

              {/* Calidad */}
              <Route path="/calidad-list" element={<Private Component={CalidadList} user={user} roles={["Administrador"]} />} />

              {/* Factores */}
              <Route path="/factores-list" element={<Private Component={FactoresList} user={user} roles={["Administrador"]} />} />

              {/* KPI Historico */}
              <Route path="/kpi-historial-list" element={<Private Component={KPIHistorial} user={user} roles={["Administrador"]} />} />

              {/* Loading */}
              <Route path="/loading" element={<Loading />} />

              {/* Settings*/}
              <Route path="/settings" element={<Private Component={Settings} user={user} roles={["Administrador"]} />} />

            </Routes>

            <SessionTimeout expirationTime={expirationTime} />
          </BrowserRouter>
      }
    </ThemeProvider>
  );
}

export default App;
