import { Card, Typography, Box, Chip, Divider, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { dashboardKPIIndividualesGapCargaInspeccion } from '../../services/dashboard';

function KpiIndividualesCargaInspeccionTabla({ filters, handleReload, reload, ...props }) {
  const [efficiency, setEfficiency] = useState([])
  const hoy = new Date(filters.fecha_fin)
  const month = hoy.getMonth() + 1; // add 1 to get the correct month number
  const monthString = month.toString().padStart(2, '0'); // pad with 0 if necessary
  const hoyFormated = `${hoy.getFullYear()}-${monthString}`

  useEffect(() => {
    async function getList() {
      try {
        const res = await dashboardKPIIndividualesGapCargaInspeccion({ filters: filters })
        const dataUltimoMes = res?.data?.map((x) => {
          const valorUltimoMes = x?.data?.find((y) => y.date === hoyFormated)
          return ({
            operador: `${x?.operador[1]}, ${x?.operador[0]}`,
            value: valorUltimoMes ? valorUltimoMes.value : 'S/D'
          })
        })

        const dataUltimoMesSorted = dataUltimoMes.sort((a, b) => {
          // handle S/D cases
          if (a.value === 'S/D' && b.value !== 'S/D') {
            return 1;
          } else if (a.value !== 'S/D' && b.value === 'S/D') {
            return -1;
          }
          // sort by value in descending order
          return b.value - a.value;
        });

        setEfficiency(dataUltimoMesSorted)
        //setObjetivos(res1.data)
      } catch (error) {
        console.log(error)
      }
    }
    getList()
  }, [filters, reload])

  return (
    <Card style={{ height: '100%' }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1
        }}
        style={{ padding: "1em 1em 0.7em 1.4em" }}
      >
        <Typography
          sx={{ m: 1 }}
          variant="h6"
          style={{ fontSize: "1em" }}
        >
          Último mes
        </Typography>
      </Box>
      <Divider />
      <Paper sx={{ overflowX: "auto", width: "100%", height: `350px` }}>
        <Table size='small' stickyHeader>
          <TableHead >
            <TableRow >
              <TableCell>
                Inspector
              </TableCell>
              <TableCell>
                Gap carga
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {efficiency.map((line, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    {line?.operador}
                  </TableCell>
                  <TableCell>
                    <Chip
                      size='small'
                      label={typeof line?.value === 'number' ? line.value.toFixed(2) : line.value}
                      color={typeof line?.value === 'number' ? (line.value > 2 ? 'error' : 'success') : 'default'}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

export default KpiIndividualesCargaInspeccionTabla