import instance from "../config/axios"

export function kpiGetAll() {
    let token = localStorage.getItem("token")
    return instance.get(`kpi/`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function kpiCreate(kpi) {
    let token = localStorage.getItem("token")
    return instance.post(`kpi/`, kpi, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function kpiEdit(id, kpi) {
    let token = localStorage.getItem("token")
    return instance.put(`kpi/${id}`, kpi, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function kpiDelete(id) {
    let token = localStorage.getItem("token")
    return instance.delete(`kpi/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

