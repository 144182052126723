import { Doughnut } from 'react-chartjs-2';
import { Box, Grid, Card, Divider, Typography } from '@mui/material';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardEnsayoUnidadTotales } from '../../services/dashboard';
import { colors_palette } from "../../utils/colors-palette";
import { StyledCardHeader } from '../../styled-components/styled-cardheader';
import { cardData } from '../../utils/data/dashboard-texts';
Chart.register(...registerables);

export const EnsayosUnidadTorta = ({ filters, ...props }) => {
  const [results, setResults] = useState([])

  useEffect(() => {
    const getData = async () => {
      const res = await dashboardEnsayoUnidadTotales({ filters: filters })
      setResults(res.data)
    }
    getData()
  }, [filters])

  let data = {
    datasets: [
      {
        data: results.map(dato => dato.cantidad),
        backgroundColor: results.map((dato, index) => colors_palette[index]),
        //borderColor: results.map((dato, index) => colors_palette[index]),
        fill: true
      }
    ],
    labels: results.map(dato => dato.unidad)
  }
  data.datasets[0].data.push(results[0] ? undefined : 50)
  data.datasets[0].backgroundColor.push(results[0] ? undefined : '#f1f1f1')
  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          footer: (ttItem) => {
            let sum = 0;
            let dataArr = ttItem[0].dataset.data;
            dataArr.map(data => {
              sum += Number(data ? data : 0);
            });
            let percentage = (ttItem[0].parsed * 100 / sum).toFixed(2) + '%';
            return `${percentage}`;
          }
        }
      },

      legend: {
        position: 'top',
        labels: {
          padding: 5,
          boxWidth: 12,
          boxHeight: 6,
        }
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <Card {...props}>
      <StyledCardHeader
      title={cardData?.ensayosPorUnidadTorta?.title}
      helpText={cardData?.ensayosPorUnidadTorta?.helpText}
      data={data}
     // genericoXLS={genericoXLS}
      />
      <Box
        sx={{
          height: 350,
          position: 'relative',
          padding: '0.5em 1em 1em 1em'
        }}
      >
        <Doughnut
          data={data}
          options={options}
        />
      </Box>
    </Card>
  );
};
