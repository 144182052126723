import { Box, Container, Grid, Typography } from '@mui/material';
import { DashboardLayout } from '../layout/layout';
import { SeguridadListResults } from '../components/seguridad-list/seguridad-list-results';
import Notification from '../styled-components/alerts/notification';
import ConfirmDialog from '../styled-components/alerts/confirm-dialog';
import { useState } from 'react';

function SeguridadList() {
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "success" })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" })

    const handleConfirmDialogChange = (value) => {
        setConfirmDialog({
            ...confirmDialog,
            ...value
        })
    }

    const handleNotifyChange = (value) => {
        setNotify(value)
    }

    return (
        <DashboardLayout>
           <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 0
          }}
        >
          <Container maxWidth='xl'  >
                    <SeguridadListResults handleConfirmDialogChange={handleConfirmDialogChange} handleNotifyChange={handleNotifyChange} />
                </Container>
            </Box>
            <Notification
                notify={notify}
                setNotify={setNotify} />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </DashboardLayout>
    )
}


export default SeguridadList;