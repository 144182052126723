import { Box, Container, Grid,Table, TableBody, TableCell, TableHead, TableRow,  Card, IconButton, Paper,  Typography } from '@mui/material';
import { DashboardLayout } from '../layout/layout';

import { useState } from 'react';
import { useEffect } from "react"
import { reporteDelete, reporteGetPDF, reportesGetAll } from '../services/reportes';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

function Reportes() {
    const [files, setFiles] = useState([])
    const [reload, setReload] = useState(false)
    
    const [pdfUrl, setPdfUrl] = useState(null);

    const searchAndShowPdf = async (fileName) => {
        if (fileName) {
            const pdfBlob = await reporteGetPDF(`reportes/${fileName}`)
            const reader = new FileReader();
            reader.onload = function () {
                const dataURL = reader.result;
                setPdfUrl(dataURL);
            };
            reader.readAsDataURL(pdfBlob);
        }

    }

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await reportesGetAll()
                await searchAndShowPdf(res?.data[0]?.name)
                setFiles(res.data)
            } catch (error) {
                console.log(error)
            }
        }
        getData()
    }, [reload])

    const handleViewClick = (filename) => {
        searchAndShowPdf(filename);
    };

    const handleDelete = (filename) => {
        reporteDelete(filename)
        handleReload()
    }

    const handleReload = () => {
        setReload(!reload)
    }

    return (
        <>
            <DashboardLayout>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 2
                    }}
                >
                    <Container maxWidth={false}>
                        <Box>
                            <Typography
                                //sx={{ m: 1 }}
                                variant="h5"
                            >
                                Reportes
                            </Typography>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={4} >
                                <Card>
                                    <Paper sx={{ overflowX: "auto", width: "100%", height: 'calc(100vh - 130px)' }}>
                                        <Table size='small' stickyHeader={true}>
                                            <TableHead style={{ height: '3em' }}>
                                                <TableRow>
                                                    <TableCell>Nombre</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {files.map((file, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{file.name}</TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                size='small'
                                                                aria-label="View"
                                                                onClick={() => handleViewClick(file.name)}
                                                            >
                                                                <VisibilityIcon fontSize='small' /> {/* Eye icon */}
                                                            </IconButton>
                                                            <IconButton
                                                                size='small'
                                                                aria-label="Delete"
                                                                onClick={() => handleDelete(file.name)}
                                                            >
                                                                <DeleteIcon fontSize='small' /> {/* Trash icon */}
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Card>
                            </Grid>
                            <Grid item xs={8}>
                                <iframe title='hola' src={pdfUrl} width="100%" style={{ height: 'calc(100vh - 130px)' }} />
                            </Grid>
                        </Grid>


                        {/*  */}
                    </Container>
                </Box>
            </DashboardLayout>
        </>
    )
}


export default Reportes;
