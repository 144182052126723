import { Box, Card, Paper, Table, TableBody, TableHead, TableRow } from "@mui/material"
import { useEffect, useState } from "react";
import { kpiGetAll } from "../../services/kpi"
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EnhancedTableToolbar from "./components/table/enhanced-table-toolbar";
import { styled } from '@mui/material/styles';

//Icons
import KpiEdit from "./components/kpi-edit";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 11,
        padding: "0px 0px 0px 8px",
        borderBottom: "0.1px solid #F5F5F5",
    }
}));

export const KPIListResults = ({ handleConfirmDialogChange, handleNotifyChange, ...props }) => {
    const [data, setData] = useState([])
    const [reload, setReload] = useState(false)

    const handleReload = () => {
        setReload(!reload)
    }

    useEffect(() => {
        const getData = async () => {
            const res = await kpiGetAll()
            setData(res.data)
        }
        getData()
    }, [reload])

    return (
        <Card>
            <EnhancedTableToolbar handleReload={handleReload} />
            <Paper sx={{ overflowX: "auto", width: "100%", height: `calc(100vh - 150px)` }}>
                <Table size="small" stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontSize: '7pt', paddingLeft: '9px' }}>
                                Fecha
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt' }} padding="none">
                                Productividad
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt' }} padding="none">
                                Eficiencia
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt' }} padding="none">
                                Calidad
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt' }} padding="none">
                                Seguridad
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt' }} padding="none">
                                Adicionales
                            </TableCell >
                            <TableCell>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((line, index) => {
                                return (
                                    <TableRow key={index}>
                                        <StyledTableCell>
                                            {line?.fecha}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" >
                                            {line?.productividad}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {line?.eficiencia}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {line?.calidad}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {line?.seguridad}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {line?.adicionales}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <KpiEdit kpi={line} handleReload={handleReload} />
                                        </StyledTableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>

                </Table>
            </Paper>
        </Card >
    )


}