import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Stack, TextField, Tooltip } from "@mui/material";
import { useState } from 'react';
import { Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DialogActions from '@mui/material/DialogActions';


//Alerts y Notifications
import Notification from '../../../styled-components/alerts/notification';
import ConfirmDialog from '../../../styled-components/alerts/confirm-dialog';

//Icons
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { reporteGenerate } from '../../../services/reportes';

function CharacterCounterTextField({ label, value, onChange, maxChars, title }) {
  const remainingChars = maxChars - value.length;
  const isExceeded = remainingChars < 0;

  return (
    <>
      <Box>
        <TextField
          label={label}
          size='small'
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={value}
          onChange={onChange}
          helperText={isExceeded ? `Excedido por ${-remainingChars} caracteres` : `${remainingChars} caracteres restantes`}
          error={isExceeded}
        />
      </Box>
    </>
  );
}

export default function ReporteGenerate({ handleReload, handleNotifyChange, filters }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "success" })
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" })
  const [reporteData, setReporteData] = useState({ title: "Reporte Mensual", comment1: "", comment2: "", comment3: "" })

  const handleReporteGenerate = async () => {
    try {
      const res = await reporteGenerate({ filters: filters, data: reporteData })
      if (res.data === "El reporte fue generado correctamente") {
        handleNotifyChange({
          isOpen: true,
          message: `${res.data}`,
          type: 'success'
        })
        setConfirmDialog({
          isOpen: false,
        })
        handleClose()
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function onSubmit() {
    setConfirmDialog({
      isOpen: true,
      title: "¿Crear un nuevo reporte?",
      subTitle: "",
      onConfirm: () => { handleReporteGenerate() },
      icon: <AddCircleOutlineIcon fontSize='inherit' color="success" />
    })
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <Tooltip title="Crear nuevo reporte">
          <IconButton color="primary" aria-label="add" onClick={handleClickOpen}>
            <NoteAddIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: 'white',
            //boxShadow: 'none',
          },
        }}

      >
        <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "#F3F4F6" }}>
          <IconButton disabled style={{ padding: "0em 0.2em 0.2em 0em" }}>
            <NoteAddIcon />
          </IconButton>
          {"Crear nuevo reporte"}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: "0em 3em 0em 3em" }}>
          <Stack direction={"column"} spacing={1} style={{ paddingTop: "1em" }}>

            <TextField
              label="Titulo del reporte"
              variant="outlined"
              fullWidth
              size='small'
              value={reporteData.title}
              onChange={(e) => setReporteData({ ...reporteData, title: e.target.value })}
            />

            <CharacterCounterTextField
              title={""}
              label="Promedio de tiempo para cada cambio de estado"
              value={reporteData.comment1}
              onChange={(e) => setReporteData({ ...reporteData, comment1: e.target.value })}
              maxChars={250}
            />
            <CharacterCounterTextField
              title={""}
              label="Situación de Inspecciones por Periodos"
              value={reporteData.comment2}
              onChange={(e) => setReporteData({ ...reporteData, comment2: e.target.value })}
              maxChars={250}
            />
            <CharacterCounterTextField
              title={""}
              label="Valor de Inspecciones Realizadas y Certificadas [USD]"
              value={reporteData.comment3}
              onChange={(e) => setReporteData({ ...reporteData, comment3: e.target.value })}
              maxChars={250}
            />
          </Stack>

          <Notification
            notify={notify}
            setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </DialogContent>
        <DialogActions >
          <Button form="myform" color="primary" variant="contained" disabled={false} fullWidth onClick={() => onSubmit()}>
            Generar Reporte
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}