import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

//Alerts y Notifications
import Notification from './alerts/notification';
import NotificationDialog from './alerts/notification-dialog';
import ConfirmDialog from './alerts/confirm-dialog';

//icons 
import EventIcon from '@mui/icons-material/Event';
import BlockIcon from '@mui/icons-material/Block';


export default function StyledChipUpdateInformacion({ handleReload, edit, value, field, id, label, onChangeFunction, rol, data, selectedToEmpty, ...props }) {
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "success" })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" })
    const [notificationDialog, setNotificationDialog] = useState({ isOpen: false, title: "", subTitle: "" })
    const [disable, setDisable] = useState(true)
    const handleDisabled = (value) => {
        setDisable(value)
    }

    useEffect(() => {
        data.trabajo_terminado === true && handleDisabled(false);
        (data.trabajo_terminado === false || rol === "Cliente") && handleDisabled(true);
    }, [
        data.trabajo_terminado, rol
    ])

    function handleRequestToChange() {
        if (disable) {
            setNotificationDialog({
                isOpen: true,
                title: `No es posible cambiar el estado`,
                onConfirm: () => {
                    setNotificationDialog({
                        ...notificationDialog,
                        isOpen: false
                    })
                },
                icon: <BlockIcon fontSize='inherit' color="error" />
            })
        } else {
            setConfirmDialog({
                isOpen: true,
                title: `¿Desea cambiar el estado de "${label}" a ${value ? "NO" : "SI"}?`,
                subTitle: value ? `Al confirmar, se eliminará la fecha registrada y no podrá volver atrás` :
                    `Al confirmar, se guardará la fecha del dia de hoy`,
                onConfirm: () => { handleEdit() },
                icon: <EventIcon fontSize='inherit' color={value ? "success" : "error"} />
            })
        }
    }


    async function handleEdit() {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        try {
            await edit({ data: { [field]: !value }, id })
            setNotify({
                isOpen: true,
                message: `El estado se modificó correctamente a ${value ? "NO" : "SI"}`,
                type: value ? "error" : "success"
            })
            handleReload()
            selectedToEmpty()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <Tooltip title={!disable ? (value ? "Cambiar a NO" : "Cambiar a SI") : "NO permitido"}>
                <Chip size="small" sx={{ width: "40px" }} label={value ? "SI" : "NO"} color={value ? "success" : "excepcional"}
                    onClick={() => {
                        handleRequestToChange()
                    }}
                    variant={disable ? 'outlined' : "filled"}
                    clickable={!disable} />
            </Tooltip>
            <Notification
                notify={notify}
                setNotify={setNotify} />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog} />
            <NotificationDialog
                confirmDialog={notificationDialog}
                setConfirmDialog={setNotificationDialog} />
        </>
    );
}

StyledChipUpdateInformacion.defaultProps = {
    selectedToEmpty: () => { }
}