import { Bar, Line } from 'react-chartjs-2';
import { Box, Grid, Card, Divider, Typography, IconButton } from '@mui/material';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardGaps } from '../../services/dashboard';
import { segmentacionSettings } from "../../utils/segmentacion-settings";
import { colors_palette } from "../../utils/colors-palette";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { genericoXLS } from '../../utils/exports/generico-xls'
import { StyledCardHeader } from '../../styled-components/styled-cardheader';
import { cardData } from '../../utils/data/dashboard-texts';
Chart.register(...registerables);

export const GapsBarra = ({ filters, ...props }) => {
  const settings = segmentacionSettings(filters.segmentacion)
  const [results, setResults] = useState([])
  useEffect(() => {
    const getData = async () => {
      const res = await dashboardGaps({ filters: filters })
      setResults(res.data)
    }
    getData()
  }, [filters])

  const data = {
    datasets: [
      /*  {
         label: "Inspeccion Realizada-Remito Realizado",
         data: results.map((d) => {
           return ({ date: d.date, value: d.inspeccionRealizada_remitoRealizado })
         }),
         borderColor: colors_palette[0]
       }, */
      {
        label: "Trabajo terminado",
        data: results?.map((d) => {
          return ({ date: d.date, value: d.inspeccionRealizada_trabajoTerminado })
        }),
        backgroundColor: colors_palette[1]
      },
      {
        label: "Informe realizado",
        data: results?.map((d) => {
          return ({ date: d.date, value: d.trabajoTerminado_informeRealizado })
        }),
        backgroundColor: colors_palette[2]
      },
      {
        label: "Informe revisado",
        data: results?.map((d) => {
          return ({ date: d.date, value: d.informeRealizado_informeRevisado })
        }),
        backgroundColor: colors_palette[3]
      },
      {
        label: "Remito emitido",
        data: results?.map((d) => {
          return ({ date: d.date, value: d.informeRevisado_remitoRealizado })
        }),
        backgroundColor: colors_palette[4]
      },
      {
        label: "Remito revisado",
        data: results?.map((d) => {
          return ({ date: d.date, value: d.remitoRealizado_remitoRevisado })
        }),
        backgroundColor: colors_palette[5]
      },
      {
        label: "Remito entregado",
        data: results?.map((d) => {
          return ({ date: d.date, value: d.remitoRevisado_remitoEntregado })
        }),
        backgroundColor: colors_palette[6]
      },
      {
        label: "Firma cliente",
        data: results?.map((d) => {
          return ({ date: d.date, value: d.remitoEntregado_remitoFirmado })
        }),
        backgroundColor: colors_palette[7]
      },
      {
        label: "Certificación",
        data: results?.map((d) => {
          return ({ date: d.date, value: d.remitoFirmado_certificadoRealizado })
        }),
        backgroundColor: colors_palette[8]
      },
      /*       {
              label: "Certificado Realizado-Certificado Finalizado",
              data: results.map((d) => {
                return ({ date: d.date, value: d.certificadoRealizado_certificadoFinalizado })
              }),
              borderColor: colors_palette[9]
            }, */
    ]
  };
  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 5,
          boxWidth: 12,
          boxHeight: 6,
        }
      },
    },
    responsive: true,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'value'
    },
    scales: {
      x: {
        
        start:0,
        stacked: true,
        type: 'time',
        time: {
          //isoWeekday:true,
          parser: settings.parser,
          unit: settings.unit,
          format: settings.timeFormat,
          tooltipFormat: settings.tooltipFormat,
          displayFormats: settings.displayFormats
        },
      },
      y: {
        min: 0,
        stacked: true,
      },
    },
  };

  return (
    <Card {...props}>
      <StyledCardHeader
        title={cardData?.promedioDeTiempoParaCadaCambioDeEstadoBarras?.title}
        helpText={cardData?.promedioDeTiempoParaCadaCambioDeEstadoBarras?.helpText}
        data={data}
        genericoXLS={genericoXLS}
      />
      <Box
        sx={{
          height: 487,
          position: 'relative',
          padding: '0.5em 1em 1em 1em'
        }}
      >
        <Bar
          data={data}
          options={options}
        />
      </Box>
    </Card>
  );
};
