export const cardData = {
  inspeccionesRealizadasNumerico: {
    title: 'Cantidad de ensayos por unidad',
    helpText: 'This is the help text fojkhkhjkhjk.'
  },
  informesPendientesNumerico: {
    title: 'Cantidad de ensayos por unidad',
    helpText: 'This is the help text for card 1.'
  },
  gapInspeccionInformeNumerico: {
    title: 'Cantidad de ensayos por unidad',
    helpText: 'This is the help text for card 1.'
  },
  gapInspeccionCertificaoNumerico: {
    title: 'Cantidad de ensayos por unidad',
    helpText: 'This is the help text for card 1.'
  },
  cantidadDeEnsayosPorUnidadBarras: {
    title: 'Cantidad de ensayos por unidad',
    helpText: `Este gráfico muestra la cantidad de ensayos realizados para cada unidad 
    en un período de tiempo específico, teniendo en cuenta únicamente las inspecciones 
    que tienen el remito realizado. Se aplican filtros de fecha, contratos, operadores, área y clientes. 
    Los ensayos se agrupan por unidad y se presentan en un gráfico de barras.`
  },
  ensayosPorUnidadTorta: {
    title: 'Ensayos por unidad',
    helpText: `El gráfico muestra la distribución de ensayos realizados por cada unidad en 
    un período de tiempo específico, considerando solo las inspecciones que tienen el remito 
    realizado. Se aplican filtros de fecha, contratos, operadores, área y clientes para obtener 
    los datos relevantes.

    En este gráfico, cada porción de la torta representa una unidad, y 
    el tamaño de cada porción refleja la cantidad de ensayos realizados 
    para esa unidad en el período seleccionado. `
  },
  valorDeEnsayosPorClienteUSDBarras: {
    title: 'Valor de ensayos por Cliente [USD]',
    helpText: 'This is the help text for card 1.'
  },
  cantidadDeEnsayosPorTipoBarras: {
    title: 'Cantidad de ensayos por tipo',
    helpText: 'This is the help text for card 1.'
  },
  valorDeEnsayosPorTipoUSDBarras: {
    title: 'Valor de ensayos por tipo [USD]',
    helpText: 'This is the help text for card 1.'
  },
  promedioDeTiempoParaCadaCambioDeEstadoLineas: {
    title: 'Promedio de tiempo para cada cambio de estado',
    helpText: 'This is the help text for card 1.'
  },
  diasPromedioTabla: {
    title: 'Días promedio',
    helpText: 'This is the help text for card 1.'
  },
  promedioDeTiempoParaCadaCambioDeEstadoBarras: {
    title: 'Promedio de tiempo para cada cambio de estado',
    helpText: 'This is the help text for card 1.'
  },
  EstadoDeInspeccionesCantidadesTorta: {
    title: 'Estado de inspecciones cantidades',
    helpText: 'This is the help text for another card.'
  },
  EstadoDeInspeccionesEconomicoTorta: {
    title: 'Estado de inspecciones económico',
    helpText: 'This is the help text for another card.'
  },
  SituacionDeInspeccionesPorPeriodosBarras: {
    title: 'Situación de inspecciones por periodos',
    helpText: 'This is the help text for another card.'
  },
  ValorDeInspeccionesRealizadasYCertificadasUSDLineas: {
    title: 'Valor de inspecciones realizadas y certificadas [USD]',
    helpText: 'This is the help text for another card.'
  },
  InfnormesPendientesPorInspectorBarras: {
    title: 'Informes pendientes por Inspector',
    helpText: 'This is the help text for another card.'
  },
  PerformanceDeInspectoresTabla: {
    title: 'Performance de Inspectores',
    helpText: 'This is the help text for another card.'
  },
  Productividad: {
    title: "Productividad",
    helpText: "This is the help text for another card."
  },
  Eficiencia: {
    title: "Eficiencia",
    helpText: "This is the help text for another card."
  },
  Seguridad: {
    title: "Seguridad",
    helpText: "This is the help text for another card."
  },
  Calidad: {
    title: "Calidad",
    helpText: "This is the help text for another card."
  },
  UltimoMes: {
    title: "Último mes",
    helpText: "This is the help text for another card."
  },
  Adicionales: {
    title: "Adicionales",
    helpText: "This is the help text for another card."
  }

};