import { Line } from 'react-chartjs-2';
import { Box, Card, Grid, IconButton, Divider, Typography } from '@mui/material';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardGetFactoresEconomicos, dashboardValorPartesCertificados } from '../../services/dashboard';
import { segmentacionSettings } from "../../utils/segmentacion-settings";
import { colors_palette } from "../../utils/colors-palette";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { genericoXLS } from '../../utils/exports/generico-xls'
import { StyledCardHeader } from '../../styled-components/styled-cardheader';
import { cardData } from '../../utils/data/dashboard-texts';

Chart.register(...registerables);

export const ValorPartesCertificados = ({ filters, ...props }) => {
  const settings = segmentacionSettings("mensual")
  const [results, setResults] = useState([])

  //Obtiene la data del gráfico y la afecta por los factores económicos
  useEffect(() => {
    const getData = async () => {
      const res = await dashboardValorPartesCertificados({ filters: { ...filters, segmentacion: "mensual" } })
      setResults(res.data)
    }
    getData()
  }, [filters])



  const data = {
    datasets: [
      {
        label: "Ensayos realizados",
        data: results?.items_no_certificados?.map((dato) => {
          return ({
            date: dato._id,
            value: dato.value
          })
        }),
        borderColor: colors_palette[7]
      },
      {
        label: "Ensayos certificados",
        data: results?.items_certificados?.map((dato) => {
          return ({
            date: dato._id,
            value: dato.value
          })
        }),
        borderColor: colors_palette[2]
      }
    ]
  };
  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        fontSize: 2,
        labels: {
          padding: 5,
          boxWidth: 12,
          boxHeight: 6,
        }
      },
    },
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'value'
    },
    scales: {
      x: {
        type: 'time',
        time: {
          parser: settings.parser,
          unit: settings.unit,
          unitStepSize: 1,
          format: settings.timeFormat,
          tooltipFormat: settings.tooltipFormat,
          displayFormats: settings.displayFormats
        },
      },

    },
  };

  return (
    <Card {...props}>
      <StyledCardHeader
        title={cardData?.ValorDeInspeccionesRealizadasYCertificadasUSDLineas?.title}
        helpText={cardData?.ValorDeInspeccionesRealizadasYCertificadasUSDLineas?.helpText}
        data={data}
        genericoXLS={genericoXLS}
      />

      <Box
        sx={{
          height: 350,
          position: 'relative',
          padding: '0.5em 1em 0.5em 1em'
        }}
      >
        <Line
          data={data}
          options={options}
        />
      </Box>
    </Card>
  );
};
