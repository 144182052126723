import instance from "../config/axios"

export function factoresGetAll() {
    let token = localStorage.getItem("token")
    return instance.get(`factores/`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function factoresCreate(factores) {
    let token = localStorage.getItem("token")
    return instance.post(`factores/`, factores, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function factoresEdit(id, factores) {
    let token = localStorage.getItem("token")
    return instance.put(`factores/${id}`, factores, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function factoresDelete(id) {
    let token = localStorage.getItem("token")
    return instance.delete(`factores/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

