import { Toolbar, Box } from "@mui/material";
import { Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import ColumnsEdit from './columns-edit';
import { styled } from '@mui/material/styles';


const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function EnhancedTableToolbar({ handleStartLoading,
  search, handleSearchChange, handleConfirmDialogChange, handleNotifyChange, numSelected,
  selected, remito, handleReload, selectedToEmpty, user, handleUserChange, fullScrean,
  order, orderBy, columns, ...props }) {

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        pt: { xs: 0, sm: 0 },
        pb: { xs: 0, sm: 0 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      variant="dense"

    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
        style={{ fontSize: "13pt" }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'wrap',
            listStyle: 'none',
            p: 0.5,
            m: 0,
          }}
          component="ul"
          style={{ width: '100%' }}>
          <ListItem>
            Parte Diario
          </ListItem>
        </Box>
      </Typography>
      <ColumnsEdit
        handleReload={handleReload}
      />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};