import { useState } from 'react';
import { DashboardLayout } from '../layout/layout';
import { Box, Container, Grid } from '@mui/material';
import FilterBar from '../components/kpi-inspectores/filter-bar';
import KpiGrupalesTabla from '../components/kpi-inspectores/kpi-grupales-tabla';
import { KPIProductividad } from '../components/kpi-inspectores/kpi-productividad';
import { KPIEficiencia } from '../components/kpi-inspectores/kpi-eficiencia';
import { KPISeguridad } from '../components/kpi-inspectores/kpi-seguridad';
import { KPICalidad } from '../components/kpi-inspectores/kpi-calidad';
import { InformesInspector } from '../components/dashboard/informes-inspector';

function KPIInspectores() {
  const d = new Date(2023, 0, 1)
  const [filters, setFilter] = useState({ contratos: [], operadores: [], fecha_inicio: d, fecha_fin: new Date(), segmentacion: "mensual", area: [], clientes: [] })
  const [reload, setReload] = useState(true);

  const handleFiltersChange = (value) => {
    setFilter({
      ...filters,
      ...value
    })
  }

  const handleReload = () => {
    setReload(!reload)

  }

  return (
    <>
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 2
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={7}
                md={7}
                xl={7}
                xs={12}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item
                    lg={12}
                    md={12}
                    xl={12}
                    xs={12}>
                    <KPIProductividad filters={filters} handleReload={handleReload} />
                  </Grid>
                  <Grid item
                    lg={12}
                    md={12}
                    xl={12}
                    xs={12}>
                    <KPIEficiencia filters={filters} handleReload={handleReload} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                xl={5}
                xs={12}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item
                    lg={12}
                    md={12}
                    xl={12}
                    xs={12}>
                    <KpiGrupalesTabla filters={filters} handleReload={handleReload} reload={reload} />
                  </Grid>
                  <Grid item
                    lg={12}
                    md={12}
                    xl={12}
                    xs={12}>
                    <KPISeguridad filters={filters} handleReload={handleReload} />
                  </Grid>
                  <Grid item
                    lg={12}
                    md={12}
                    xl={12}
                    xs={12}>
                    <KPICalidad filters={filters} handleReload={handleReload} />
                  </Grid>

                </Grid>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <InformesInspector filters={filters} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </DashboardLayout>
    </>
  )
}

export default KPIInspectores;
