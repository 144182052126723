import { useEffect, useState, useContext } from 'react';
import { DashboardLayout } from '../layout/layout';
import { Box, Container, Grid } from '@mui/material';
import FilterBar from '../components/dashboard-clients/filter-bar';
import { EnsayosUnidadBarras } from '../components/dashboard-clients/ensayos-unidad-barras';
import { SituacionInformesRemitados } from '../components/dashboard-clients/situacion-informes-remitados';
import { GapsTable } from '../components/dashboard-clients/gaps-table';
import { dashboardIndicadoresNumericos } from '../services/dashboard';
import { GapsLinea, GapsLineaRX } from '../components/dashboard-clients/gaps-linea-rx';
import { GapsBarra } from '../components/dashboard-clients/gaps-barra';
import { EstadosTorta } from '../components/dashboard-clients/estados-torta';
import UserContext from '../context/userContext';
import { EnsayosTipoActividadBarras } from '../components/dashboard-clients/ensayos-tipo-actividad-barras';
import { GapsLineaInsp } from '../components/dashboard-clients/gaps-linea-insp';
import { EnsayosUnidadBarrasAgrupado } from '../components/dashboard-clients/ensayos-unidad-barras-agrupado';

function DashboardClients() {

  const d = new Date(2022, 8, 1)
  const [filters, setFilter] = useState({ contratos: [], operadores: [], fecha_inicio: d, fecha_fin: new Date(), segmentacion: "mensual", area: [], clientes: ['626d99480581fea5022d628e'] })

  const handleFiltersChange = (value) => {
    setFilter({
      ...filters,
      ...value
    })
  }

  return (
    <>
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 2
          }}
        >
          <Container maxWidth={false}>


            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <FilterBar handleFiltersChange={handleFiltersChange} filters={filters} />
              </Grid>
              
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <EnsayosUnidadBarras filters={filters} />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <EnsayosUnidadBarrasAgrupado filters={filters} />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <EnsayosTipoActividadBarras filters={filters} />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xl={6}
                xs={12}
              >
                <GapsLineaRX filters={filters} />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xl={6}
                xs={12}
              >
                <GapsLineaInsp filters={filters} />
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                xl={4}
                xs={12}
              >
                <GapsTable filters={filters} />
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xl={8}
                xs={12}
              >
                <GapsBarra filters={filters} />
              </Grid>
                <>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xl={4}
                    xs={12}
                  >
                    <EstadosTorta filters={filters} />
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    md={8}
                    xl={8}
                    xs={12}
                  >
                    <SituacionInformesRemitados filters={filters} />
                  </Grid>
                </>
            </Grid>
          </Container>
        </Box>
      </DashboardLayout>
    </>
  )
}

export default DashboardClients;
