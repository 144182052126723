import { Box, Card, IconButton, ListItem, Paper, Table, TableBody, TableHead, TableRow, Toolbar, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { factoresDelete, factoresGetAll } from "../../services/factores"
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EnhancedTableToolbar from "./components/table/enhanced-table-toolbar";
import { styled } from '@mui/material/styles';

//Icons
import FactorEdit from "./components/factores-edit";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 11,
        padding: "0px 0px 0px 8px",
        borderBottom: "0.1px solid #F5F5F5",
        //bordetTop:4
    }
}));

export const FactoresListResults = ({ handleConfirmDialogChange, handleNotifyChange, ...props }) => {
    const [data, setData] = useState([])
    const [reload, setReload] = useState(false)

    const handleReload = () => {
        setReload(!reload)
    }


    useEffect(() => {
        const getData = async () => {
            const res = await factoresGetAll()
            setData(res.data)
        }
        getData()
    }, [reload])

    return (
        <Card>
            <EnhancedTableToolbar handleReload={handleReload} />
            <Paper sx={{ overflowX: "auto", width: "100%", height: `calc(100vh - 150px)` }}>
                <Table size="small" stickyHeader={true}>
                    <TableHead>
                    <TableRow>
                            <TableCell style={{ fontSize: '7pt', paddingLeft: '9px'  }}>
                                Fecha
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt'  }} padding="none">
                                Precio Dolar [$]
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt'  }} padding="none">
                                Costo Medio [USD]
                            </TableCell>
                            <TableCell align="center" colSpan={5} style={{ fontSize: '7pt', backgroundColor:'#F5F5F5'  }} padding="none">
                                Productividad
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt'  }} padding="none">
                                Eficiencia
                            </TableCell>
                            <TableCell  align="right" style={{ fontSize: '7pt', backgroundColor:'#F5F5F5' }} padding="none">
                                Calidad
                            </TableCell >
                            <TableCell align="right" style={{ fontSize: '7pt'  }} padding="none">
                                Seguridad
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: '7pt', paddingLeft: '9px' }}>
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt' }} padding="none">
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt' }} padding="none">

                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt', backgroundColor:'#F5F5F5'  }} padding="none">
                                Excepcional
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt', backgroundColor:'#F5F5F5'  }} padding="none">
                                Excelente
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt', backgroundColor:'#F5F5F5'  }} padding="none">
                                Muy buena
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt', backgroundColor:'#F5F5F5'  }} padding="none">
                                Buena
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt', backgroundColor:'#F5F5F5'  }} padding="none">
                                Justo
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt'  }} padding="none">
                                Buena
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: '7pt', backgroundColor:'#F5F5F5'  }} padding="none">
                                Buena
                            </TableCell >
                            <TableCell align="right" style={{ fontSize: '7pt' }} padding="none">
                                Buena
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((line, index) => {
                            return (
                                <TableRow key={index}>
                                    <StyledTableCell>
                                        {line.fecha}
                                    </StyledTableCell>
                                    <StyledTableCell align="right" >
                                        {line.precio_dolar.toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {line.costo_medio_usd.toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {line.productividad_excepcional.toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {line.productividad_excelente.toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {line.productividad_muy_bueno.toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {line.productividad_bueno.toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {line.productividad_justo.toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {line.eficiencia_bueno.toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {line.calidad_bueno.toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {line.seguridad_bueno.toFixed(2)}
                                    </StyledTableCell>

                                    <StyledTableCell>
                                        <FactorEdit factor={line} handleReload={handleReload} />
                                    </StyledTableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>

                </Table>
            </Paper>
        </Card >
    )


}