import { Bar } from 'react-chartjs-2';
import { Box, Card, Divider, Grid, Typography, IconButton } from '@mui/material';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import {  dashboardEnsayoUnidadBarrasAgrupado } from '../../services/dashboard';
import { colors_palette } from "../../utils/colors-palette";
import { segmentacionSettings } from "../../utils/segmentacion-settings";
import { dateFill } from '../../utils/date-fill';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { genericoXLS } from '../../utils/exports/generico-xls'
Chart.register(...registerables);

export const EnsayosUnidadBarrasAgrupado = ({ filters, ...props }) => {
  const settings = segmentacionSettings(filters.segmentacion)
  const [results, setResults] = useState([])
  useEffect(() => {
    const getData = async () => {
      const res = await dashboardEnsayoUnidadBarrasAgrupado({ filters: filters })
      setResults(res.data)
    }
    getData()
  }, [filters])
  const data = {
    datasets:
      results.map((dato, index) => {
        return (
          {
            label: dato._id,
            data: dateFill(dato.cantidades, filters.segmentacion, filters.fecha_inicio, filters.fecha_fin),
            backgroundColor: colors_palette[index]
          })
      })
    ,
  };

  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 5,
          boxWidth: 12,
          boxHeight: 6,
        }
      },
    },
    responsive: true,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'value'
    },
    scales: {
      x: {
        stacked: true,
        type: 'time',
        time: {
          //isoWeekday:true,
          parser: settings.parser,
          unit: settings.unit,
          format: settings.timeFormat,
          tooltipFormat: settings.tooltipFormat,
          displayFormats: settings.displayFormats
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <Card {...props}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          //justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
        style={{ padding: "1em 1em 0.3em 1.4em" }}
      >
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item style={{ width: '85%' }}>
            <Typography
              sx={{ m: 1 }}
              variant="h6"
              style={{ fontSize: "1em" }}
            >
              Cantidad de ensayos por unidad Polietileno/Cracker
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ m: 1 }}>
              <IconButton
                //color=""
                variant="contained"
                size='small'
                onClick={() => { genericoXLS({ data: data.datasets }) }}
              >
                <FileDownloadIcon fontSize='inerhit' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box
        sx={{
          height: 350,
          position: 'relative',
          padding: '0.5em 1em 1em 1em'
        }}
      >
        <Bar
          data={data}
          options={options}
        />
      </Box>
    </Card>
  );
};
