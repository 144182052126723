import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { TextField} from '@mui/material';
import StyledChipFilter from '../../../styled-components/styled-chip-filter'
import StyledDatepickerFilter from '../../../styled-components/styled-datepicker-filter';
import { Fragment } from 'react';
import { contractGetNames, contractGetAreas } from '../../../services/contracts';
import { clientGetNames } from '../../../services/clients';
import { userGetNames } from '../../../services/users';
import AutocompleteSearch from '../../../styled-components/styled-autocomplete-search';
import AutocompleteSearchSimple from '../../../styled-components/styled-autocomplete-searchsimple';


function EnhancedTableSearch({ handleStartLoading, search, columns, handleSearchChange, ...props }) {

    return (
        <TableRow style={{ height: "30px", backgroundColor: "#F3F4F6" }}>
            {columns.map((column) => {
                function handleChange(value) {
                    handleStartLoading()
                    handleSearchChange({ ...search, [column.id.replace("[", ".").replace("]", "")]: value })
                }
                return (
                    <Fragment key={column.id}>
                        {column.show &&
                            <TableCell
                                //padding={column.disablePadding ? 'none' : 'normal'}
                                padding='none'
                                style={{ backgroundColor: "#F3F4F6" }}>
                                {column.type === "text" &&
                                    <TextField
                                        defaultValue={column.search}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e)=>handleChange(e.target.value)}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: 30,
                                                fontSize: 12,
                                            }
                                        }}
                                        placeholder='-'
                                    />
                                }
                                {column.type === "number" &&
                                    <TextField
                                        defaultValue={column.search}
                                        size="small"
                                        fullWidth
                                        placeholder='-'
                                        variant="outlined"
                                        type="number"
                                        onChange={(e)=>handleChange(e.target.value)}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: 30,
                                                fontSize: 12,
                                            }
                                        }}
                                    />
                                }
                                 {column.type === "contratos" &&
                                    <AutocompleteSearch handleFiltersChange={handleChange} id="contratos" getFunction={contractGetNames} search={search || []}/> 
                                }
                                {column.type === "operadores" &&
                                    <AutocompleteSearch handleFiltersChange={handleChange} id="operador" getFunction={userGetNames} search={search || []}/> 
                                }
                                {column.type === "clientes" &&
                                    <AutocompleteSearch  handleFiltersChange={handleChange} id="cliente" getFunction={clientGetNames} search={search || []}/> 
                                } 
                                {column.type === "paga" &&
                                    <AutocompleteSearch handleFiltersChange={handleChange} id="paga" getFunction={clientGetNames} search={search || []}/> 
                                } 
                                {column.type === "area" &&
                                    <AutocompleteSearchSimple handleFiltersChange={handleChange} id="area" getFunction={contractGetAreas} search={search || []}/> 
                                } 
                                {column.type === "date" &&
                                    <StyledDatepickerFilter onChange={handleChange} />
                                }
                                {(column.type === "select" || column.type ==="select-informacion") &&
                                    <StyledChipFilter default_value={column.search} onChange={handleChange} />
                                }
                                {column.type === "none" &&
                                    <>
                                    </>
                                }
                            </TableCell>
                        }
                    </Fragment>
                )
            })}
        </TableRow>
    );
}


export default EnhancedTableSearch