import { Line } from 'react-chartjs-2';
import { Box, Grid, Card, Divider, Typography, IconButton, Tooltip } from '@mui/material';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardGaps, dashboardGetGapsObjetivos, dashboardSetGapsObjetivos } from '../../services/dashboard';
import { segmentacionSettings } from "../../utils/segmentacion-settings";
import { colors_palette } from "../../utils/colors-palette";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { genericoXLS } from '../../utils/exports/generico-xls'
import GapsObjetivo from './components/gaps-objetivos';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { StyledCardHeader } from '../../styled-components/styled-cardheader';
import { cardData } from '../../utils/data/dashboard-texts';
Chart.register(...registerables);

export const GapsLinea = ({ filters, ...props }) => {
  const settings = segmentacionSettings(filters.segmentacion)
  const [results, setResults] = useState([])
  const [gaps, setGaps] = useState({ inspeccion_informe: 0, inspeccion_certificado: 0 })
  const [open, setOpen] = useState(false)

  const handleOpenChange = () => {
    setOpen(!open)
  }

  const handleGapsChange = (value) => {
    dashboardSetGapsObjetivos(value)
    setGaps(value)

  }

  useEffect(() => {
    const getData = async () => {
      const res1 = await dashboardGetGapsObjetivos()
      const res = await dashboardGaps({ filters: filters })
      setResults(res.data)
      setGaps(res1.data)
    }
    getData()
  }, [filters])

  //Obtiene las fechas de inicio y fin redondeadas
  const data = {
    datasets: [
      //Valores objetivos
      {
        label: "Inspección - Informe (Objetivo)",
        data: [
          { date: results[0]?.date, value: gaps.inspeccion_informe },
          { date: results[results?.length - 1]?.date, value: gaps.inspeccion_informe }],
        borderColor: colors_palette[2],
        borderWidth: 1
      },
      {
        label: "Inspección - Certificado (Objetivo)",
        data: [
          { date: results[0]?.date, value: gaps.inspeccion_certificado },
          { date: results[results?.length - 1]?.date, value: gaps.inspeccion_certificado }],
        borderColor: colors_palette[0],
        borderWidth: 1
      },

      //GAPS
      {
        label: "Inspeccion - Certificado",
        data: results.map((d) => {
          return ({ date: d.date, value: d.inspeccionRealizada_certificadoRealizado })
        }),
        borderColor: colors_palette[0]
      },
      {
        label: "Inspección - Informe",
        data: results.map((d) => {
          return ({ date: d.date, value: d.trabajoTerminado_informeRealizado })
        }),
        borderColor: colors_palette[2]
      },
    ]
  };
  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        fontSize: 2,
        labels: {
          padding: 5,
          boxWidth: 12,
          boxHeight: 6,
        }
      },
    },
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'value'
    },
    scales: {
      x: {
        type: 'time',
        time: {
          parser: settings.parser,
          unit: settings.unit,
          format: settings.timeFormat,
          tooltipFormat: settings.tooltipFormat,
          displayFormats: settings.displayFormats
        },
      },
      y: {
        min: 0,
      }
    },
  };

  return (
    <Card {...props}>
      <StyledCardHeader
        title={cardData?.promedioDeTiempoParaCadaCambioDeEstadoLineas?.title}
        helpText={cardData?.promedioDeTiempoParaCadaCambioDeEstadoLineas?.helpText}
        data={data}
        genericoXLS={genericoXLS}
      />
      <Box
        sx={{
          height: 350,
          position: 'relative',
          padding: '0.5em 1em 0.5em 1em'
        }}
      >
        <Line
          data={data}
          options={options}
        />
      </Box>
      <GapsObjetivo gaps={gaps} handleGapsChange={handleGapsChange} expanded={true} open={open}></GapsObjetivo>
    </Card>
  );
};
