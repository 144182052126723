export const partes_columns = [
    { header: "Id ", key: "Id", width: 25 },
    { header: "Número de Reporte", key: "numero_reporte", width: 25 },
    { header: "Número de Orden", key: "numero_orden", width: 25 },
    { header: "Unidad", key: "unidad", width: 25 }, 
    { header: "TAG", key: "tag", width: 25 },
    { header: "Equipo detalle", key: "tag_detalle", width: 25 },
    { header: "Operador", key: "operador", width: 25 },
    { header: "Cantidad", key: "cantidad", width: 25 },
    { header: "Unidad de Medida", key: "unidad_medida", width: 25 },
    { header: "Cliente", key: "cliente", width: 25 },
    { header: "Área", key: "area", width: 25 },
    { header: "Contrato", key: "contrato", width: 25 },
    { header: "Descripción de Servicio", key: "descripcion_servicio", width: 25 },
    { header: "Fecha de Carga", key: "fecha_carga", width: 25 },
    { header: "Fecha de Inspección", key: "fecha_inspeccion", width: 25 },
    { header: "Trabajo Terminado", key: "trabajo_terminado", width: 25 },
    { header: "Trabajo Terminado Fecha", key: "trabajo_terminado_fecha", width: 25 },
    { header: "Informe Realizado", key: "informe_realizado", width: 25 },
    { header: "Informe Realizado Fecha", key: "informe_realizado_fecha", width: 25 },
    { header: "Informe Revisado", key: "informe_revisado", width: 25 },
    { header: "Informe Revisado Fecha", key: "informe_revisado_fecha", width: 25 },
    { header: "Tipo de Actividad", key: "tipo_actividad", width: 25 },
    { header: "Remito Número", key: "remito_numero", width: 25 },
    { header: "Remito Realizado", key: "remito_realizado", width: 25 },
    { header: "Remito Realizado Fecha", key: "remito_realizado_fecha", width: 25 },
    { header: "Remito Revisado", key: "remito_revisado", width: 25 },
    { header: "Remito Revisado Fecha", key: "remito_revisado_fecha", width: 25 },
    { header: "Remito Entregado", key: "remito_entregado", width: 25 },
    { header: "Remito Entregado Fecha", key: "remito_entregado_fecha", width: 25 },
    { header: "Remito Firmado", key: "remito_firmado", width: 25 },
    { header: "Remito Firmado Fecha", key: "remito_firmado_fecha", width: 25 },
    { header: "Certificado Número", key: "certificado_numero", width: 25 },
    { header: "Certificado Realizado", key: "certificado_realizado", width: 25 },
    { header: "Certificado Realizado Fecha", key: "certificado_realizado_fecha", width: 25 },
    { header: "Certificado Finalizado", key: "certificado_finalizado", width: 25 },
    { header: "Cetificado Finalizado Fecha", key: "certificado_finalizado_fecha", width: 25 },
    { header: "Certificante", key: "certificante", width: 25 },
    { header: "Clase", key: "clase", width: 25 },
    { header: "Código de Servicio", key: "codigo_servicio", width: 25 },
    { header: "AA/mm Inspección", key: "AAMM_inspeccion", width: 25 },
    { header: "AA/sem Inspección", key: "AAsem_inspeccion", width: 25 },
    { header: "Observaciones", key: "observaciones", width: 25 },
    { header: "Paga", key: "paga", width: 25 },
    { header: "Valor Unitario", key: "valor_unitario", width: 25 },
    { header: "Valor Total", key: "valor_total", width: 25 },

]

export const partes_campos_predeterminados = [
    { header: 'Id ', key: 'Id', width: 25 },
    { header: "Fecha de Inspección", key: "fecha_inspeccion", width: 25 },
    { header: 'Número de Orden', key: 'numero_orden', width: 25 },
    { header: "Número de Reporte", key: "numero_reporte", width: 25 },
    { header: "TAG", key: "tag", width: 25 },
    { header: "Cantidad", key: "cantidad", width: 25 },
    { header: 'Descripción de Servicio', key: 'descripcion_servicio', width: 25 },
    { header: 'Unidad', key: 'unidad', width: 25 },
    { header: 'Operador', key: 'operador', width: 25 },
    { header: 'Trabajo Terminado', key: 'trabajo_terminado', width: 25 },
    { header: 'Informe Realizado', key: 'informe_realizado', width: 25 },
    { header: 'Informe Revisado', key: 'informe_revisado', width: 25 },
    { header: 'Remito Realizado', key: 'remito_realizado', width: 25 },
    { header: "Remito Número", key: "remito_numero", width: 25 },
    { header: "Certificante", key: "certificante", width: 25 },
]

export const remitos_columns = [
    { header: "Remito Número", key: "remito_numero", width: 25 },
    { header: "Certificante", key: "certificante", width: 25 },
    { header: "Cliente", key: "cliente", width: 25 },
    { header: "Contrato", key: "contrato", width: 25 },
    { header: "Operador", key: "operador", width: 25 },
    { header: "Remito Fecha", key: "fecha", width: 25 },
    { header: "Remito Revisado", key: "remito_revisado", width: 25 },
    { header: "Remito Revisado Fecha", key: "remito_revisado_fecha", width: 25 },
    { header: "Remito Entregado", key: "remito_entregado", width: 25 },
    { header: "Remito Entregado Fecha", key: "remito_entregado_fecha", width: 25 },
    { header: "Remito Firmado", key: "remito_firmado", width: 25 },
    { header: "Remito Firmado Fecha", key: "remito_firmado_fecha", width: 25 },
    { header: "Certificado Número", key: "certificado_numero", width: 25 },
    { header: "Certificado Realizado", key: "certificado_realizado", width: 25 },
    { header: "Certificado Realizado Fecha", key: "certificado_realizado_fecha", width: 25 },
    { header: "Certificado Finalizado", key: "certificado_finalizado", width: 25 },
    { header: "Cetificado Finalizado Fecha", key: "certificado_finalizado_fecha", width: 25 },
    { header: "Unidad", key: "planta", width: 25 },
]

export const remitos_campos_predeterminados = [
    { header: "Remito Número", key: "remito_numero", width: 25 },
    { header: "Remito Fecha", key: "fecha", width: 25 },
    { header: "Cliente", key: "cliente", width: 25 },
    { header: "Contrato", key: "contrato", width: 25 },
    { header: "Unidad", key: "planta", width: 25 },
    { header: "Remito Revisado", key: "remito_revisado", width: 25 },
    { header: "Remito Entregado", key: "remito_entregado", width: 25 },
    { header: "Remito Firmado", key: "remito_firmado", width: 25 },
    { header: "Certificado Número", key: "certificado_numero", width: 25 },
    { header: "Certificado Realizado", key: "certificado_realizado", width: 25 },
    { header: "Certificado Finalizado", key: "certificado_finalizado", width: 25 },
    { header: "Certificante", key: "certificante", width: 25 },
]

export const certificados_columns = [
    { header: "Certificado Número", key: "certificado_numero", width: 25 },
    { header: "Certificante", key: "certificante", width: 25 },
    { header: "Cliente", key: "cliente", width: 25 },
    { header: "Contrato", key: "contrato", width: 25 },
    { header: "Operador", key: "operador", width: 25 },
    { header: "Certificado Realizado", key: "certificado_realizado", width: 25 },
    { header: "Certificado Realizado Fecha", key: "certificado_realizado_fecha", width: 25 },
    { header: "Certificado Finalizado", key: "certificado_finalizado", width: 25 },
    { header: "Cetificado Finalizado Fecha", key: "certificado_finalizado_fecha", width: 25 },
    { header: "Certificado Facturado", key: "certificado_facturado", width: 25 },
    { header: "Cetificado Facturado Fecha", key: "certificado_facturado_fecha", width: 25 },
    { header: "Unidad", key: "planta", width: 25 },
    { header: "Valor", key: "valor_certificado", width: 25 },
    { header: "HES", key: "hes", width: 25 },
    { header: "Número Factura", key: "factura_numero", width: 25 },
    
]

export const certificados_campos_predeterminados = [
    { header: "Certificado Número", key: "certificado_numero", width: 25 },
    { header: "Certificante", key: "certificante", width: 25 },
    { header: "Cliente", key: "cliente", width: 25 },
    { header: "Contrato", key: "contrato", width: 25 },
    { header: "Operador", key: "operador", width: 25 },
    { header: "Certificado Realizado", key: "certificado_realizado", width: 25 },
    { header: "Certificado Realizado Fecha", key: "certificado_realizado_fecha", width: 25 },
    { header: "Certificado Finalizado", key: "certificado_finalizado", width: 25 },
    { header: "Cetificado Finalizado Fecha", key: "certificado_finalizado_fecha", width: 25 },
    { header: "Certificado Facturado", key: "certificado_facturado", width: 25 },
    { header: "Cetificado Facturado Fecha", key: "certificado_facturado_fecha", width: 25 },
    { header: "Unidad", key: "planta", width: 25 },
    { header: "Valor", key: "valor_certificado", width: 25 },
    { header: "HES", key: "hes", width: 25 },
    { header: "Número Factura", key: "factura_numero", width: 25 },
] 