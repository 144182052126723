import { Line } from 'react-chartjs-2';
import { Box, Grid, Card, Divider, Typography } from '@mui/material';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardKPIGrupal } from '../../services/dashboard';
import { segmentacionSettings } from "../../utils/segmentacion-settings";
import { useTheme } from '@mui/material/styles';
import { StyledCardHeader } from '../../styled-components/styled-cardheader';
import { cardData } from '../../utils/data/dashboard-texts';
Chart.register(...registerables);

export const KPIProductividad = ({ filters, handleReload, ...props }) => {
  const settings = segmentacionSettings('mensual');
  const [results, setResults] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const getData = async () => {
      const res = await dashboardKPIGrupal({ filters: filters })
      setResults(res.data)
    }

    getData()
  }, [filters])

  //Obtiene las fechas de inicio y fin redondeadas
  const data = {
    datasets: [
      {
        label: "Productividad",
        data: results.map((d) => {
          return ({ date: d.fecha, value: d.productividad })
        }),
        borderColor: '#534c47',
        yAxisID: 'y1',
      },
      {
        label: "Productividad Histórico",
        data: results.map((d) => {
          return ({ date: d.fecha, value: d.productividad_historico })
        }),
        borderColor: '#534c47',
        borderWidth: 0.7,
        yAxisID: 'y1',
      },

      {
        label: "Productividad Excepcional",
        data: results.map((d) => {
          return ({ date: d.fecha, value: 8 })
        }),
        backgroundColor: `${theme.palette.excepcional.light}`,
        borderWidth: 0,
        yAxisID: 'y1',
        fill: '+1',
        pointRadius: 0,
      },
      {
        label: "Productividad Excelente",
        data: results.map((d) => {
          return ({ date: d.fecha, value: d.productividad_excepcional })
        }),
        backgroundColor: `${theme.palette.excelente.light}`,
        borderWidth: 0,
        yAxisID: 'y1',
        fill: '+1',
        pointRadius: 0,
      },
      {
        label: "Productividad Muy buena",
        data: results.map((d) => {
          return ({ date: d.fecha, value: d.productividad_excelente })
        }),
        backgroundColor: `${theme.palette.muybueno.light}`,
        borderWidth: 0,
        yAxisID: 'y1',
        fill: '+1',
        pointRadius: 0,
      },
      {
        label: "Productividad Buena",
        data: results.map((d) => {
          return ({ date: d.fecha, value: d.productividad_muy_bueno })
        }),
        backgroundColor: `${theme.palette.bueno.light}`,
        borderWidth: 0,
        yAxisID: 'y1',
        fill: '+1',
        pointRadius: 0,
      },
      {
        label: "Productividad Justo",
        data: results.map((d) => {
          return ({ date: d.fecha, value: d.productividad_bueno })
        }),
        backgroundColor: `${theme.palette.justo.light}`,
        borderWidth: 0,
        yAxisID: 'y1',
        fill: '+1',
        pointRadius: 0,
      },
      {
        label: "Productividad Isuficiente",
        data: results.map((d) => {
          return ({ date: d.fecha, value: d.productividad_justo })
        }),
        backgroundColor: `${theme.palette.insuficiente.light}`,
        borderWidth: 0,
        yAxisID: 'y1',
        fill: '+1',
        pointRadius: 0,
      },
      {
        label: "Productividad Isuficiente",
        data: results.map((d) => {
          return ({ date: d.fecha, value: d.productividad_insuficiente })
        }),
        backgroundColor: `#1218284D`,
        borderWidth: 0,
        yAxisID: 'y1',
        fill: 'origin',
        pointRadius: 0,
      },
    ]
  };
  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index'
      }
    },
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'value',
    },
    scales: {
      x: {
        type: 'time',
        time: {
          parser: settings.parser,
          unit: settings.unit,
          format: settings.timeFormat,
          tooltipFormat: settings.tooltipFormat,
          displayFormats: settings.displayFormats
        },
      },
      y1: {
        min: 4,
        type: 'linear',
        display: true,
        grid: {
          drawOnChartArea: false,
        }
      },
    },
  };

  return (
    <Card {...props}>
      <StyledCardHeader
        title={cardData?.Productividad?.title}
        helpText={cardData?.Productividad?.helpText}
        data={data}
      />
      <Box
        sx={{
          height: 350,
          position: 'relative',
          padding: '0.5em 1em 0.5em 1em'
        }}
      >

        <Line
          data={data}
          options={options}
        />
      </Box>
    </Card>
  );
};
