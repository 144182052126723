import React, { useState } from "react";
import { Collapse, TextField, Box, Grid, Table, TableHead, TableBody, TableRow, Paper } from '@mui/material';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 11,
        padding: "0px 17px 0px 17px",
        borderBottom: "0.1px solid #F5F5F5",
        //bordetTop:4
    }
}));

function CostoMedioUSDRow({ costos, value, date, handleCostosChange, expanded, open, index, ...props }) {
    const [costo, setCosto] = useState(value)

    const handleChange = ({ index, value }) => {
        let newCostoes = costos
        newCostoes[index].value = Number(value)
        handleCostosChange(newCostoes)
        setCosto(value)
    }

    return (

        <TableRow >
            <StyledTableCell style={{ width: "80px" }}>
                
                {date}
            </StyledTableCell>
            <StyledTableCell align="right">
                <TextField
                    required
                    variant="standard"
                    style={{ width: "50px" }}
                    inputProps={{ style: { fontSize: "0.7em" } }}
                    value={costo || ''}
                    size="small"
                    type="number"
                    onChange={(event) => handleChange({ index, value: event.target.value })}
                />
            </StyledTableCell>
            <StyledTableCell align="right">
                <TextField
                    required
                    variant="standard"
                    style={{ width: "50px" }}
                    inputProps={{ style: { fontSize: "0.7em" } }}
                    value={costo || ''}
                    size="small"
                    type="number"
                    onChange={(event) => handleChange({ index, value: event.target.value })}
                />
            </StyledTableCell>
            <StyledTableCell align="right">
                <TextField
                    required
                    variant="standard"
                    style={{ width: "50px" }}
                    inputProps={{ style: { fontSize: "0.7em" } }}
                    value={costo || ''}
                    size="small"
                    type="number"
                    onChange={(event) => handleChange({ index, value: event.target.value })}
                />
            </StyledTableCell>
            <StyledTableCell align="right">
                <TextField
                    required
                    variant="standard"
                    style={{ width: "50px" }}
                    inputProps={{ style: { fontSize: "0.7em" } }}
                    value={costo || ''}
                    size="small"
                    type="number"
                    onChange={(event) => handleChange({ index, value: event.target.value })}
                />
            </StyledTableCell>
            <StyledTableCell align="right">
                <TextField
                    required
                    variant="standard"
                    style={{ width: "50px" }}
                    inputProps={{ style: { fontSize: "0.7em" } }}
                    value={costo || ''}
                    size="small"
                    type="number"
                    onChange={(event) => handleChange({ index, value: event.target.value })}
                />
            </StyledTableCell>
            <StyledTableCell align="right">
                <TextField
                    required
                    variant="standard"
                    style={{ width: "50px" }}
                    inputProps={{ style: { fontSize: "0.7em" } }}
                    value={costo || ''}
                    size="small"
                    type="number"
                    onChange={(event) => handleChange({ index, value: event.target.value })}
                />
            </StyledTableCell>
            <StyledTableCell align="right">
                <TextField
                    required
                    variant="standard"
                    style={{ width: "50px" }}
                    inputProps={{ style: { fontSize: "0.7em" } }}
                    value={costo || ''}
                    size="small"
                    type="number"
                    onChange={(event) => handleChange({ index, value: event.target.value })}
                />
            </StyledTableCell>
            <StyledTableCell align="right">
                <TextField
                    required
                    variant="standard"
                    style={{ width: "50px" }}
                    inputProps={{ style: { fontSize: "0.7em" } }}
                    value={costo || ''}
                    size="small"
                    type="number"
                    onChange={(event) => handleChange({ index, value: event.target.value })}
                />
            </StyledTableCell>
        </TableRow>

    );
}

export default CostoMedioUSDRow;