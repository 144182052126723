import { Bar } from 'react-chartjs-2';
import { Box, Card, Divider, Grid, Typography, IconButton, Tooltip, Switch, FormControlLabel } from '@mui/material';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardEnsayoTipoActividadBarrasEconomico, dashboardGetFactoresEconomicos } from '../../services/dashboard';
import { colors_palette } from "../../utils/colors-palette";
import { segmentacionSettings } from "../../utils/segmentacion-settings";
import { dateFill } from '../../utils/date-fill';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { genericoXLS } from '../../utils/exports/generico-xls'
import { StyledCardHeader } from '../../styled-components/styled-cardheader';
import { cardData } from '../../utils/data/dashboard-texts';
Chart.register(...registerables);

export const EnsayosTipoActividadBarrasEconomico = ({ filters, ...props }) => {
  const settings = segmentacionSettings('mensual')
  const [results, setResults] = useState([])
  const [reload, setReload] = useState(false)
  const [open, setOpen] = useState(false)
  const [agrupado, setAgrupado] = useState(true)


  useEffect(() => {
    const getData = async () => {
      const res1 = await dashboardGetFactoresEconomicos();
      const res = await dashboardEnsayoTipoActividadBarrasEconomico({
        filters: {
          ...filters,
          segmentacion: 'mensual',
          agrupado: agrupado ? ['RD', 'RC', 'IV', 'PT', 'MT', 'EXT', 'CAL', 'UT', 'INT'] : []
        }
      })
      
      let resultados = res.data?.map(resultado => {
        const cantidades = resultado?.cantidades?.map((cantidad) => {
          let factor = res1?.data.filter((y) => y.date === cantidad.date)
          let dolar_price = factor[0]?.dolar_price || 1
          return ({ date: cantidad.date, value: cantidad.value / dolar_price })
        })
        return ({ _id: resultado._id, cantidades: cantidades })
      });
      setResults(resultados)
    }
    getData()
  }, [filters, reload, agrupado])


  const data = {
    datasets:
      results.map((dato, index) => {
        return (
          {
            label: dato._id,
            data: dateFill(dato.cantidades, 'mensual', filters.fecha_inicio, filters.fecha_fin),
            backgroundColor: colors_palette[index]
          })
      })
    ,
  };

  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 5,
          boxWidth: 12,
          boxHeight: 6,
        }
      },
    },
    responsive: true,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'value'
    },
    scales: {
      x: {
        stacked: true,
        type: 'time',
        time: {
          //isoWeekday:true,
          parser: settings.parser,
          unit: settings.unit,
          format: settings.timeFormat,
          tooltipFormat: settings.tooltipFormat,
          displayFormats: settings.displayFormats
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <Card {...props}>
      <StyledCardHeader
        title={cardData?.valorDeEnsayosPorTipoUSDBarras?.title}
        helpText={cardData?.valorDeEnsayosPorTipoUSDBarras?.helpText}
        data={data}
        genericoXLS={genericoXLS}>
        <FormControlLabel control={<Switch defaultChecked onChange={() => setAgrupado(!agrupado)} />} label={'Agrupar'} />
        </StyledCardHeader>
      <Box
        sx={{
          height: 350,
          position: 'relative',
          padding: '0.5em 1em 1em 1em'
        }}
      >
        <Bar
          data={data}
          options={options}
        />
      </Box>
    </Card>
  );
};
