import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, useMediaQuery } from '@mui/material';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Users as UsersIcon } from '../icons/users';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ViewListIcon from '@mui/icons-material/ViewList';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { Logo } from '../components/logo';
import { NavItem } from './nav-item';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaidIcon from '@mui/icons-material/Paid';
import UserContext from '../context/userContext';
import { useContext } from 'react';
import styled from "styled-components";
import { ReactComponent as GieLogo } from "../images/gie_blanco.svg";
import SettingsIcon from '@mui/icons-material/Settings';
import { NavItemGruped } from './nav-item-gruped'

//Icons
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DatasetIcon from '@mui/icons-material/Dataset';
import SummarizeIcon from '@mui/icons-material/Summarize';

import { Typography } from '@mui/material';
import { default as LinkMaterial } from '@mui/material/Link';

const StyledLogo = styled(GieLogo)`
  width: 8em;
  display: block;
  margin: auto;
`;


const items = [
  {
    href: '/',
    icon: (<HomeIcon fontSize="small" />),
    title: 'Inicio',
    roles: ["Cliente"]
  },
  {
    href: '/partes-list-client',
    icon: (<ViewListIcon fontSize="small" />),
    title: 'Parte Diario',
    roles: ["Cliente"]
  },
  {
    href: '/',
    icon: (<HomeIcon fontSize="small" />),
    title: 'Inicio',
    roles: ["Administrador", "Supervisor", "Inspector", "Asistente"]
  },
  {
    icon: (<BarChartIcon fontSize="small" />),
    title: 'Análisis',
    roles: ["Administrador", "Supervisor"],
    subitems: [
      {
        href: '/dashboard',
        icon: (<BarChartIcon fontSize="small" />),
        title: 'Dashboard',
        roles: ["Administrador", "Supervisor"]
      },
      {
        href: '/kpi',
        icon: (<BarChartIcon fontSize="small" />),
        title: 'KPI',
        roles: ["Administrador"]
      },
      {
        href: '/dashboard-cliente',
        icon: (<ShowChartIcon fontSize="small" />),
        title: 'DOW',
        roles: ["Administrador"]
      },
      {
        href: '/reportes',
        icon: (<SummarizeIcon fontSize="small" />),
        title: 'Reportes',
        roles: ["Administrador"]
      },
    ]
  },
  {
    href: '/partes-list',
    icon: (<ViewListIcon fontSize="small" />),
    title: 'Parte Diario',
    roles: ["Administrador", "Supervisor", "Inspector", "Asistente"]
  },
   {
    href: '/kpi-inspectores',
    icon: (<BarChartIcon fontSize="small" />),
    title: 'KPI',
    roles: ["Inspector","Supervisor","Asistente"]
  },
  {
    href: '/remitos-list',
    icon: (<ReceiptIcon fontSize="small" />),
    title: 'Remitos',
    roles: ["Administrador", "Supervisor", "Asistente"]
  },
  {
    href: '/certif-list',
    icon: (<PaidIcon fontSize="small" />),
    title: 'Certificados',
    roles: ["Administrador", "Asistente"]
  },
  {
    href: '/clients-list',
    icon: (<SupervisedUserCircleIcon fontSize="small" />),
    title: 'Clientes',
    roles: ["Administrador"]
  },
  {
    href: '/users-list',
    icon: (<UsersIcon fontSize="small" />),
    title: 'Usuarios',
    roles: ["Administrador"]
  },
  {
    href: '/contracts-list',
    icon: (<HistoryEduIcon fontSize="small" />),
    title: 'Contratos',
    roles: ["Administrador"]
  },
  {
    icon: (<DatasetIcon fontSize="small" />),
    title: 'Factores',
    roles: ["Administrador"],
    subitems: [
      {
        href: '/seguridad-list',
        icon: (<BarChartIcon fontSize="small" />),
        title: 'Seguridad',
        roles: ["Administrador", "Supervisor"]
      },
      {
        href: '/calidad-list',
        icon: (<BarChartIcon fontSize="small" />),
        title: 'Calidad',
        roles: ["Administrador"]
      },
      {
        href: '/factores-list',
        icon: (<BarChartIcon fontSize="small" />),
        title: 'General',
        roles: ["Administrador"]
      },
      {
        href: '/kpi-historial-list',
        icon: (<BarChartIcon fontSize="small" />),
        title: 'KPI Historial',
        roles: ["Administrador"]
      },
    ]
  },
  
  {
    href: '/settings',
    icon: (<SettingsIcon fontSize="small" />),
    title: 'Configuracion',
    roles: ["Administrador"]
  },
];

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  const [user, setUser] = useContext(UserContext);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });
  let items_filtrados = items.filter((items) => {
    return (items.roles?.includes(user.role))
  })
  useEffect(
    () => {
      if (open) {
        onClose?.();
      }
    },
    []
  );

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <StyledLogo />
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 0
          }}
        />
        <Box sx={{ flexGrow: 1, my: 2 }} >
          {items_filtrados.map((item) => (
            <Fragment key={item.title}>
              {item.subitems ?
                <NavItemGruped
                  key={item.title}
                  icon={item.icon}
                  href={item.href}
                  title={item.title}
                  subitems={item.subitems}
                /> :
                <NavItem
                  key={item.title}
                  icon={item.icon}
                  href={item.href}
                  title={item.title}
                />
              }
            </Fragment>

          ))}
        </Box>
        <Divider sx={{ borderColor: '#2D3748' }} />

        <div style={{ padding: "2em 3em 1em 3em" }} >

          <Typography variant="caption" display="block" gutterBottom style={{ textSize: "0.5em" }}>
            Developed by
          </Typography>
          <LinkMaterial target="_blank" href="http://growup-digital.com" rel="noreferrer">
            <Logo
              sx={{
                width: 50
              }}

            />
          </LinkMaterial>
        </div>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: '#19334F',
            color: '#FFFFFF',
            //width: 280
            width: 200
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 200
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
